import { OnesignalService } from './../services/onesignal/onesignal.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { LoginService, WebsocketService } from './../services/service.index';
import { JWT_TOKEN, USER_STORAGE, ONESIGNAL } from '../config/store';
import { UsersService } from '../services/users/users.service';
import { User } from '../interfaces/user.interface';

declare function init_plugins(): any;
import swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public email: string;
  public recuerdame = false;
  public loadingButton = false;

  constructor(
    public router: Router,
    public _login: LoginService,
    public _user: UsersService,
    private _ws: WebsocketService,
    private _onSignal: OnesignalService
  ) {}

  ngOnInit() {
    init_plugins();
  }

  ingresar(form: NgForm) {

    if ( form.invalid ) {
      return;
    }
    this.loadingButton = true;

    const user = {
      email: form.value.email,
      password: form.value.password
    };

    this._login.onLogin(user)
      .subscribe((response: any) => {

        if (response.jwtAuth) {

          const token = response.token;
          localStorage.setItem(JWT_TOKEN, token);

          this._user.getAuthUser()
          .subscribe((_user: User) => {

            if (_user) {

              const user_role = _user.role;

              if (user_role === '100' || user_role === '1') {

                const logUser = JSON.stringify(_user);
                localStorage.setItem(USER_STORAGE, logUser);

                this._user.loadStorage();

                this.router.navigate(['/escritorio']);

                const completeName = `${_user.name} ${_user.last_name}`;

                this._ws.loginSockets(completeName)
                  .then((resp: any) => {
                    console.log(resp);
                  });

                  const oneSignal = JSON.parse(localStorage.getItem(ONESIGNAL));

                  this._onSignal.updateLocalUserProfile(_user.id, oneSignal)
                    .subscribe((_userWithPushID: any) => console.log(_userWithPushID));
                } else {
                  swal('Error al iniciar sesión', 'Parece que no tienes permitido entrar.', 'error');
                }
              }
            });


        } else {
          localStorage.setItem(JWT_TOKEN, '');
        }
        this.loadingButton = false;
      }, () => {

        swal('Error al iniciar sesión', 'Revisa que tu correo y contraseña son correctos.', 'error');
        this.loadingButton = false;
      });
    // this.router.navigate(['/escritorio']);
  }

}
