import { Component, OnInit } from '@angular/core';
import { DashboardService, WebsocketService } from './../../services/service.index';
import { ToastrService } from 'ngx-toastr';

declare function init_plugins(): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  connectedUsers: any[] = [];

  constructor(
    public _wsService: WebsocketService,
    private _dashboardService: DashboardService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {

    init_plugins();
    this._dashboardService.emitActiveUsers();
    this._dashboardService.getActiveUsers()
      .subscribe((resp: any) => {
        console.log(resp);
        this.connectedUsers = resp;
      });
  }

  toastrTest() {
    console.log('toast');
    this.toastr.success('Hello world!', 'Toastr fun!');
  }

}
