import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SettingsService } from './../../services/service.index';
import { State } from '../../interfaces/state.interface';
import { FormGroup, FormControl } from '@angular/forms';

import * as jsts from 'jsts';
import swal from 'sweetalert2';

@Component({
  selector: 'app-setting-places',
  templateUrl: './setting-places.component.html',
  styleUrls: ['./setting-places.component.css']
})
export class SettingPlacesComponent implements OnInit {

  @ViewChild('settingsMap') settingsMap: ElementRef;
  @ViewChild('pacCard') card: ElementRef;
  @ViewChild('placeInput') input: ElementRef;

  private map: google.maps.Map;
  private geocoder: google.maps.Geocoder;
  public states: State[] = [];
  public state: string;
  public location: any;

  // Maps
  public all_overlays: any[] = [];

  public locationForm: FormGroup;
  public places: any[] = [];

  constructor(private _settingsService: SettingsService) { }

  ngOnInit() {
    this.getPlaces();
    this.initForm();
    this.getCountryStates();
    this.initMap();
  }

  initForm() {
    this.locationForm = new FormGroup({
      'country': new FormControl('MX'),
      'state': new FormControl('0')
    });

    const isState = this._settingsService.loadSelectedState();

    if (isState) {
      this.locationForm.get('state').setValue(isState.state);
    } else {
      this.locationForm.get('state').setValue('0');
    }
  }

  getCountryStates() {
    const mx_code = 'MX';
    this._settingsService.getCountryStates(mx_code)
      .subscribe((response: any) => {
        const data = response.data;
        const states = data;
        this.states = states;
      });
  }

  getPlaces() {
    this._settingsService.getAllLocations()
      .subscribe((response: any) => {
        const data = response.data;
        this.places = data;
        // console.log(this.places);
        this.loadPolygons(this.places);
      });
  }

  loadPolygons(places: any) {

    for (const place of places) {
      const decodedPolygon = google.maps.geometry.encoding.decodePath(place.coords);
      // console.log(decodedPolygon);
      const newLocation = new google.maps.Polygon({
        paths: decodedPolygon,
        fillColor: '#007bff',
        fillOpacity: 0.4,
        strokeColor: '#007bff',
        strokeWeight: 2,
        // editable: true
      });
      newLocation.setMap(this.map);
    }
  }

  getSelectedState(state: string) {
    this.state = state;
    // console.log(this.state);
    this.setMapCenter(this.state, this.geocoder, this.map);
  }

  setMapCenter(address: any, geocoder: google.maps.Geocoder, map: google.maps.Map) {

    geocoder.geocode({'address': address}, (results: any, status: any) => {
      if (status === 'OK') {
        map.setCenter(results[0].geometry.location);
        this.location = results[0].geometry.location;

        const selectedState = {
          state: this.state,
          lat: this.location.lat(),
          lng: this.location.lng(),
        };
        // console.log(selectedState);
        const sendValue = this._settingsService.storageSelectedState(selectedState);
        this.locationForm.get('state').setValue(sendValue.state);
      }
    });
  }

  initMap() {

    const state = this._settingsService.loadSelectedState();
    let latLng: any;

    if (state) {
      latLng = new google.maps.LatLng( state.lat, state.lng );
    } else {
      latLng = new google.maps.LatLng( 20.68467375, -103.36144185 );
    }


    const mapaOpciones: google.maps.MapOptions = {
      center: latLng,
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      zoomControl: true,
    };

    const polyOptions: google.maps.PolygonOptions = {
      fillColor: '#007bff',
      fillOpacity: 0.4,
      strokeColor: '#007bff',
      strokeWeight: 2,
      editable: true
    };

    this.map = new google.maps.Map(this.settingsMap.nativeElement, mapaOpciones);

    this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(this.card.nativeElement);

    this.geocoder = new google.maps.Geocoder();

    this.setMapCenter(this.state, this.geocoder, this.map);

    const drawingManager = new google.maps.drawing.DrawingManager({
      drawingControlOptions: {
        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
      },
      polygonOptions: polyOptions,
      map: this.map
    });

    const options: google.maps.places.AutocompleteOptions = {
      componentRestrictions: {country: 'mx'},
    };

    const autocomplete = new google.maps.places.Autocomplete(this.input.nativeElement, options);

    // autocomplete.setComponentRestrictions(
    //   {'country': ['mx']}
    // );

    const infowindow = new google.maps.InfoWindow();

    const infowindowContent = document.getElementById('infowindow-content');
    infowindow.setContent(infowindowContent);

    const marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
      position: new google.maps.LatLng(0, 0),
    });

    /**
     * Listener Autocomplete
     */
    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();


      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        console.log(`No details available for input: ${ place.name }`);
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
        // console.log('viewport');
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
        // console.log('center');
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = '';

      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || ''),
        ].join(' ');
      }

      infowindowContent.children['place-icon'].src = place.icon;
      infowindowContent.children['place-name'].textContent = place.name;
      infowindowContent.children['place-address'].textContent = address;
      // infowindow.open(this.map, marker);
    });

    // this.bindDataLayerListeners(this.map.data);
    // this.getPlaces();

    /**
     * listener drawinManager
     *
     * the response is polygon type
     */
    google.maps.event.addListener(drawingManager, 'overlaycomplete', (response: any) => {

      /**
       * Calculate polygin intersections
       */
      this.calcIntersection(response.overlay, this.all_overlays);
      this.all_overlays.push(response.overlay);

      this.savePolygons(response.overlay, this.state);
      // this.getPlaces();
    });
  }

  private calcIntersection(newOverlay: any, allOverlays: any) {

    // ensure the polygon contains enought vertices
    if (newOverlay.getPath().getLength() < 3) {
      console.log('Not enought vertices. Draw a polygon that contains at least  3 vertices.');
      return;
    }

    const geometryFactory = new jsts.geom.GeometryFactory();

    const newPolygon = this.createJstsPolygon(geometryFactory, newOverlay);

    // iterate existing polygons and find if a new polygon interse
    const result = allOverlays.filter((currentOverlay) => {

      const currentPolygon = this.createJstsPolygon(geometryFactory, currentOverlay);
      const intersection = newPolygon.intersection(currentPolygon);

      return intersection.isEmpty() === false;
    });

    // if new polygon intersects any of exiting ones, draw it with green color
    if (result.length > 0) {
      newOverlay.setOptions({strokeWeight: 2.0, fillColor: '#dc3545', strokeColor: '#dc3545'});
    }
  }

  private createJstsPolygon(geometryFactory: any, overlay: any): jsts.geom.Polygon {

    const path = overlay.getPath();
    const coordinates = path.getArray().map((coord) => {
      return new jsts.geom.Coordinate(coord.lat(), coord.lng());
    });

    coordinates.push(coordinates[0]);
    const shell = geometryFactory.createLinearRing(coordinates);

    return geometryFactory.createPolygon(shell);
  }

  private savePolygons(newOverlay: any, state) {

    const path = newOverlay.getPath();
    console.log(path);
    const encodeString = google.maps.geometry.encoding.encodePath(path);
    let data: any;
    const _state = this._settingsService.loadSelectedState();

    if (encodeString) {
      data = {
        state: _state.state,
        coords: encodeString
      };
    }

    this._settingsService.storeLocation(data)
      .subscribe((response) => {
        console.log(response);
        swal('¡Bien!', 'Zona agregada', 'success');

      });

    console.log(data);
  }

  deletePlace(place: any) {

    swal({
      title: 'Borrar lugar',
      text: `¿Quieres borrar el lugar: ${ place.name }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this._settingsService.deleteLocation(place.id)
        .subscribe((response: any) => {
          const data = response.data;
          this.getPlaces();
          this.initMap();
          swal('¡Borrado!', `El producto ${ data.name } ha sido borrado`, 'error');
          window.scrollTo(0, 0);
        }, error => {
          console.log(error);
        });
      }
    });
  }

}
