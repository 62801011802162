import { Injectable, Injector } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent,
    HttpErrorResponse
} from '@angular/common/http';
import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/catch';
import { take, filter, catchError, switchMap, finalize } from 'rxjs/operators';
import { JWT_TOKEN } from './../../config/store';
import { LoginService } from './../../services/login/login.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector) {}

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token }});
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent |
    HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        const token = localStorage.getItem(JWT_TOKEN);

        return next.handle(this.addToken(req, token)).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 400:
                            return this.handle400Error(error);
                        case 401:
                            return this.handle401Error(req, next);
                        default:
                            return observableThrowError(error);
                    }
                } else {
                    return observableThrowError(error);
                }
            }));
    }

    handle400Error(error: HttpErrorResponse) {
        const authService = this.injector.get(LoginService);

        if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
            // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
            return authService.logout();
        }

        return observableThrowError(error);
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService = this.injector.get(LoginService);

            return authService.refreshToken().pipe(
                switchMap((newToken: any) => {
                    if (newToken) {

                        const _newToken = newToken.token;

                        this.tokenSubject.next(_newToken);

                        // console.log(_newToken);

                        localStorage.setItem(JWT_TOKEN, _newToken);
                        return next.handle(this.addToken(req, _newToken));
                    }

                    // If we don't get a new token, we are in trouble so logout.
                    return authService.logout();
                }),
                catchError(error => {
                    // If there is an exception calling 'refreshToken', bad news so logout.
                    return authService.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }), );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                }), );
        }
    }
}
