import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from './../../services/service.index';

import swal from 'sweetalert2';

@Component({
  selector: 'app-setting-prices',
  templateUrl: './setting-prices.component.html',
  styleUrls: ['./setting-prices.component.css']
})
export class SettingPricesComponent implements OnInit {

  public morningPriceForm: FormGroup;
  public afternoonPriceForm: FormGroup;
  public nightPriceForm: FormGroup;

  // Morning
  public morningStartTime: NgbTimeStruct;
  public morningEndTime: NgbTimeStruct;
  // Afternoon
  public afternoonStartTime: NgbTimeStruct;
  public afternoonEndTime: NgbTimeStruct;
  // Night
  public nightStartTime: NgbTimeStruct;
  public nightEndTime: NgbTimeStruct;

  constructor(
    private _settingsService: SettingsService,
  ) { }

  ngOnInit() {
    this.initForm();
    this.getSchedulePrices();
  }

  initForm() {
    this.morningPriceForm = new FormGroup({
      'morning_percentage': new FormControl('', Validators.required)
    });

    this.afternoonPriceForm = new FormGroup({
      'afternoon_percentage': new FormControl('', Validators.required)
    });

    this.nightPriceForm = new FormGroup({
      'night_percentage': new FormControl('', Validators.required)
    });
  }

  /**
   * Metodo que carga los formularios
   * con el porcentaje y los horarios
   */
  getSchedulePrices() {
    this._settingsService.getSchedulePrices()
      .subscribe((response: any) => {
        const data = response.data;
        const schedule = data;

        // Fill all schedule
        this.fillTimepickers(schedule);

        this.morningPriceForm.get('morning_percentage').setValue(schedule[0].schedule_percentage);
        this.afternoonPriceForm.get('afternoon_percentage').setValue(schedule[1].schedule_percentage);
        this.nightPriceForm.get('night_percentage').setValue(schedule[2].schedule_percentage);
      });
  }

  updateMorningPrice(data: any) {

    data.schedule_start_time = `${ this.morningStartTime.hour }:${ this.morningStartTime.minute }`;
    data.schedule_end_time = `${ this.morningEndTime.hour }:${ this.morningEndTime.minute }`;
    data.schedule_percentage = data.morning_percentage;

    this.updateAllPrices(data, 1);
  }

  updateAfternoonPrice(data: any) {
    data.schedule_start_time = `${ this.afternoonStartTime.hour }:${ this.afternoonStartTime.minute }`;
    data.schedule_end_time = `${ this.afternoonEndTime.hour }:${ this.afternoonEndTime.minute }`;
    data.schedule_percentage = data.afternoon_percentage;

    this.updateAllPrices(data, 2);
  }

  updateNightPrice(data: any) {

    data.schedule_start_time = `${ this.nightStartTime.hour }:${ this.nightStartTime.minute }`;
    data.schedule_end_time = `${ this.nightEndTime.hour }:${ this.nightEndTime.minute }`;
    data.schedule_percentage = data.night_percentage;

    this.updateAllPrices(data, 3);
  }

  updateAllPrices(data: any, scheduleId: number) {

    this._settingsService.updatePricePercentage(data, scheduleId)
    .subscribe((response: any) => {
      const _data = response.data;
      const schedule = _data;
      swal('¡Actualizado!', `El horario ${ schedule.schedule_name } ha sido actualizado`, 'success');
    }, error => {
      console.error(error);
      if (error.error) {
        swal('Error', error.error.schedule_percentage[0], 'error');
      }
    });

  }

  fillTimepickers(schedule: any) {
    // Morning Start Time Schedule
    const morningStartTime = schedule[0].schedule_start_time.split(':');

    const morningStartHour = parseInt(morningStartTime[0], 10);
    const morningStartMinute = parseInt(morningStartTime[1], 10);
    const morningStartSecond = parseInt(morningStartTime[2], 10);

    this.morningStartTime = { hour: morningStartHour, minute: morningStartMinute, second: morningStartSecond };

    // Morning End Time Schedule
    const morningEndTime = schedule[0].schedule_end_time.split(':');

    const morningEndHour = parseInt(morningEndTime[0], 10);
    const morningEndMinute = parseInt(morningEndTime[1], 10);
    const morningEndSecond = parseInt(morningEndTime[2], 10);

    this.morningEndTime = { hour: morningEndHour, minute: morningEndMinute, second: morningEndSecond };

    // Afternoon Start Time Schedule
    const afternoonStartTime = schedule[1].schedule_start_time.split(':');

    const afternoonStartHour = parseInt(afternoonStartTime[0], 10);
    const afternoonStartMinute = parseInt(afternoonStartTime[1], 10);
    const afternoonStartSecond = parseInt(afternoonStartTime[2], 10);

    this.afternoonStartTime = { hour: afternoonStartHour, minute: afternoonStartMinute, second: afternoonStartSecond };

    // Afternoon End Time Schedule
    const afternoonEndTime = schedule[1].schedule_end_time.split(':');

    const afternoonEndHour = parseInt(afternoonEndTime[0], 10);
    const afternoonEndMinute = parseInt(afternoonEndTime[1], 10);
    const afternoonEndSecond = parseInt(afternoonEndTime[2], 10);

    this.afternoonEndTime = { hour: afternoonEndHour, minute: afternoonEndMinute, second: afternoonEndSecond };

    // Night Start Time Schedule
    const nightStartTime = schedule[2].schedule_start_time.split(':');

    const nightStartHour = parseInt(nightStartTime[0], 10);
    const nightStartMinute = parseInt(nightStartTime[1], 10);
    const nightStartSecond = parseInt(nightStartTime[2], 10);

    this.nightStartTime = { hour: nightStartHour, minute: nightStartMinute, second: nightStartSecond };

    // Night End Time Schedule
    const nightEndTime = schedule[2].schedule_end_time.split(':');

    const nightEndHour = parseInt(nightEndTime[0], 10);
    const nightEndMinute = parseInt(nightEndTime[1], 10);
    const nightEndSecond = parseInt(nightEndTime[2], 10);

    this.nightEndTime = { hour: nightEndHour, minute: nightEndMinute, second: nightEndSecond };
  }

}
