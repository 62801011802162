import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from './../../config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Category } from '../../interfaces/category.model';
import { JWT_TOKEN } from '../../config/store';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  public headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  /**
   * Function to get all categories
   *
   */
  getAllCategories() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/categories?token=${ jwtToken }`;

    return this.http.get( url, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to store in local storage the categories
   *
   * @param categories Category
   */
  storeCategories(categories: Category[]) {
    localStorage.setItem('categories', JSON.stringify(categories));
  }

  /**
   * Method to load the categories from localStorage
   */
  loadCategories() {
    if (localStorage.getItem('categories')) {
      return JSON.parse(localStorage.getItem('categories'));
    } else {
      return [];
    }
  }

  /**
   * Method to get a category for specified product
   *
   * @param product_id number
   */
  getProductCategory(product_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/products/${ product_id }/categories?token=${ jwtToken }`;

    return this.http.get( url )
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to get a specified category
   *
   * @param category_id number
   */
  getSpecifiedCategory(category_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/categories/${ category_id }?token=${ jwtToken }`;

    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
   * Method to store a category
   *
   * @param category any
   */
  storeCategory(category: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/categories?token=${ jwtToken }`;

    return this.http.post(url, category, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to delete a specified category
   *
   * @param category Category
   */
  deleteCategory(category: Category) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/categories/${ category.id }?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  updateCategory(category: Category, category_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/categories/${ category_id }?token=${ jwtToken }`;

    return this.http.put( url, category, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  setExtraCategoryProducts(categories: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/settings/extra?token=${ jwtToken }`;

    return this.http.post( url, categories, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  getExtraCategoryProducts() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/settings/extra?token=${ jwtToken }`;

    return this.http.get( url, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
