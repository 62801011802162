import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription, interval } from 'rxjs';
import { OrdersService, UsersService, DeliverService } from './../../services/service.index';
import { User } from './../../interfaces/user.interface';
import { WebsocketService } from './../../services/websocket/websocket.service';
import { USER_STORAGE } from './../../config/store';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import swal from 'sweetalert2';

@Component({
  selector: 'app-detail-order',
  templateUrl: './detail-order.component.html',
  styleUrls: ['./detail-order.component.css']
})
export class DetailOrderComponent implements OnInit, OnDestroy {

  @ViewChild('map') mapElement: ElementRef;
  public map: google.maps.Map;

  marcadores: google.maps.Marker[] = [];
  public delivery_time: NgbTimeStruct;
  meridian = true;

  private order_id: number;
  private order_subs: Subscription;
  private watchPositionSub: Subscription;
  private watchOrderRejSub: Subscription;
  private watchDeliverSubs: Subscription;
  public order: any;
  public orderProducts: any;
  public orderPromotions: any;
  public dealers: User[] = [];
  public repartidor: any;
  serviceCost: number = null;

  public orderForm: FormGroup;

  constructor(
    public _orderService: OrdersService,
    private activatedRoute: ActivatedRoute,
    private _userService: UsersService,
    private _ws: WebsocketService,
    private _deliver: DeliverService,
  ) {
    this.getUrlParam();
    this.delivery_time = {hour: 0, minute: 0, second: 0};
  }

  ngOnInit() {
    this.watchDeliver();
    this.watchDeliverPosition();
    this.watchOrderRejSubected();
    this.initForm();
    this.getDealers();
    this.onGetServiceCost();
    this.onGetOrder(this.order_id);
  }

  ngOnDestroy() {
    this.order_subs.unsubscribe();
    this.watchPositionSub.unsubscribe();
    this.watchOrderRejSub.unsubscribe();
    this.watchDeliverSubs.unsubscribe();
  }

  goSetRoom(order: any) {

    if (localStorage.getItem(USER_STORAGE)) {

      const user = JSON.parse(localStorage.getItem(USER_STORAGE));
      const completeName = `${ user.name } ${ user.last_name }`;
      const order_id = order.order_id;

      this._ws.loginSockets(completeName, 'admin', order_id);
    }
  }

  onGetServiceCost() {
    this._orderService.getServiceCost()
    .subscribe((responseData: any) => {
      const data = responseData.data;
      this.serviceCost = data[0].service_cost;
    });
  }

  initMap() {
    const latLng = new google.maps.LatLng( 20.68467375, -103.36144185 );

    const mapaOpciones: google.maps.MapOptions = {
      center: latLng,
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapaOpciones);

    const geocoder = new google.maps.Geocoder();

    this.geocodeAddress(geocoder, this.map);
  }

  geocodeAddress(geocoder: any, resultsMap: google.maps.Map) {

    const address = this.order.billing_address;

    const fullAddress = address;

    geocoder.geocode({'address': fullAddress}, (results: any, status: any) => {
      if (status === 'OK') {
        resultsMap.setCenter(results[0].geometry.location);
        this.addMarker(resultsMap, results[0].geometry.location);
      } else {
        console.error(`Geocode was not successful for the following reason: ${ status }`);
      }
    });

  }

  addMarker(map: any, location: any) {

    const _marker = new google.maps.Marker({
      map: map,
      animation: google.maps.Animation.DROP,
      position: location,
      draggable: false
    });

    this.marcadores.push(_marker);
  }

  onGetOrder(order_id: any) {
    this.order_subs = this._orderService.getSpecifiedOrder(order_id)
      .subscribe((response: any) => {
        const data = response.data;
        const order = data;
        this.order = order;

        if (this.order.delivery_time) {
          const delivery_time = this.order.delivery_time;
          const getDateTime = delivery_time.split(' ');
          const getTime = getDateTime[1].split(':');
          console.log(delivery_time);

          // const payload = {
          //   delivery_hour: delivery_time
          // };

          // this._ws.emit('delivery.hour', payload);

          const hour = parseInt(getTime[0], 10);
          const minute = parseInt(getTime[1], 10);
          const second = parseInt(getTime[2], 10);

          this.delivery_time = {
            hour,
            minute,
            second
          };
        }

        if (this.order.dealer_id) {
          const dealer_id = parseInt(this.order.dealer_id, 10);

          this._orderService.getOrderDeliver(dealer_id)
            .subscribe((resp: any) => {

              const _data = resp.data;
              this.repartidor = _data;
              this.orderForm.get('dealer').setValue(this.order.dealer_id);

            });
        }

        this.orderForm.get('order_status').setValue(this.order.order_status);
        this.initMap();
        this.onGetOrderProduct(order_id);
        this.onGetOrderPromotion(order_id);
        this.goSetRoom(this.order);
      });
  }

  onGetOrderProduct(order_id: number) {

    this._orderService.getOrderProducts(order_id)
    .subscribe((_response: any) => {
      const _data = _response.data;
      const orderProducts = _data;
      this.orderProducts = orderProducts;
      console.log(this.orderProducts);
    });
  }

  onGetOrderPromotion(order_id: any) {

    this._orderService.getOrderPromotions(order_id)
      .subscribe((_respData: any) => {
        const _data = _respData.data;
        const orderPrmotions = _data;
        this.orderPromotions = orderPrmotions;
        console.log(this.orderPromotions);
      });
  }

  initForm() {
    this.orderForm = new FormGroup({
      'order_status': new FormControl('', Validators.required),
      'dealer': new FormControl('', Validators.required)
    });
  }

  sendOrder(order: any) {
    console.log(order);
  }

  getDealers() {
    this._userService.getDealers()
      .subscribe((response: any) => {
        const data = response.data;
        const dealers = data;
        this.dealers = dealers;
        // console.log(this.dealers);
      });
  }

  /**
   * Function to get the product id from de url
   */
  getUrlParam() {
    this.activatedRoute.params.subscribe( params => {
      this.order_id = params['id'];
    });
  }

  setDeliveryMan(event: any) {

    // Get delivery man name
    const options = event.target['options'];
    const index = options.selectedIndex;

    const deliveryName = options[index].text;

    swal({
      title: 'Asignar repartidor',
      text: `¿Quieres asignar a ${ deliveryName } para enviar el pedido?`,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, asignar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        console.log(event.target.value);
        const dealer = {
          dealer_id: event.target.value
        };
        this._orderService.updateOrderWithDealer(this.order_id, dealer)
          .subscribe((response: any) => {
            console.log(response);

            this.order.products = this.orderProducts;
            const data = {
              order: this.order,
              user_id: parseInt(event.target.value, 10)
            };

            this._deliver.sendPushToUser(data)
              .subscribe((status: any) => {
                console.log(status);
                swal('¡Repartido asignado!', 'Repartidor asignado correctamente', 'success');
              });
          });
      }
    });
  }

  changeOrderStatus(event: any) {

    const options = event.target['options'];
    const index = options.selectedIndex;

    const status = options[index].text;

    swal({
      title: 'Cambiar estado',
      text: `¿Cambiar el estado del pedido a ${ status }?`,
      type: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí, cambiar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        const order_status = {
          order_status: event.target.value,
        };

        this._orderService.updateOrderStatus(this.order_id, order_status)
          .subscribe((response: any) => {

            const order = response.data;
            const _status = {
              order_status: order.order_status,
              room: order.order_id,
              order
            };

            const payload = {
              nombre: `${ this._userService.user.name } ${ this._userService.user.last_name }`,
              sala: 'admin',
              current_order: 'sin-orden'
            };

            const currentOrderRoom = {
              room: order.order_id
            };

            if (order.order_status === 20) {
              console.log(_status);
              this._ws.emit('admin.rejected.order', _status);
              this._orderService.updateOrderStatus(this.order_id, _status)
              .subscribe((resp: any) => {

                const orderResp = resp.data;

                const rejectOrderResp = {
                  order: orderResp,
                  user_id: orderResp.buyer_id
                };
                console.log(rejectOrderResp);

                this._deliver.sendPushToUser(rejectOrderResp)
                  .subscribe((respData: any) => {
                    console.log(respData);
                    swal('¡Pedido cancelado!', `El pedido ${ order.order_id } ha sido cancelado`, 'success');
                  });


                this._ws.emit('unsubscribe', currentOrderRoom);
                this._ws.emit('configurar-usuario', payload, () => {});
              });
            }

            if (order.order_status === 10) {
              console.log('orden finalizada');
              this._ws.emit('admin.end.order', _status);
              this._orderService.updateOrderStatus(this.order_id, _status)
              .subscribe((order_response: any) => {

                const orderResp = order_response.data;

                const endStatusOrder = {
                  order: orderResp,
                  user_id: orderResp.buyer_id
                };
                console.log(endStatusOrder);

                this._deliver.sendPushToUser(endStatusOrder)
                  .subscribe((respData: any) => {
                    console.log(respData);
                    swal('¡Pedido entregado!', `El pedido ${ order.order_id } ha finalizado`, 'success');
                  });

                this._ws.emit('unsubscribe', currentOrderRoom);
                this._ws.emit('configurar-usuario', payload, () => {});
              });
            }

            swal('¡Estado cambiado!', 'Estado cambiado correctamente', 'success');
          });
      }
    });

  }

  searchDeliver() {
    swal({
      title: 'Buscar repartidor',
      text: 'Se enviará una alerta a todos los repartidores registrados en la plataforma',
      type: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sí, notificar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        // Se envia una notificación push a todos los repartidores registrados
        this.order.products = this.orderProducts;
        const data = {
          order: this.order
        };

        this._deliver.sendPushNotification(data)
          .subscribe((response: any) => console.log(response));
      }
    });
  }

  watchDeliverPosition() {

    this.watchPositionSub = this._ws.listen('order.delivery.position')
      .subscribe((data: any) => {
        console.log(data);
        const dealerLatLng = data;
        this.setMarkerPosition(this.marcadores[1], dealerLatLng);
      });
  }

  setMarkerPosition(marker: google.maps.Marker, position: any) {

    marker.setPosition(
        new google.maps.LatLng(
            position.lat,
            position.lng)
    );
  }


  watchDeliver() {
    this.watchDeliverSubs = this._ws.listen('repartidor.order_accepted')
      .subscribe((data: any) => {
        console.log(data);
        const repartidor = data.repartidor;
        this.repartidor = repartidor;
        const coords = data.deliver_coords;
        this.addMarker(this.map, coords);
        swal({
          title: 'Han aceptado el pedido',
          text: `El repartidor ${ repartidor.name } ha aceptado el pedido`,
          type: 'info',
        });

      });
  }

  watchOrderRejSubected() {
    this.watchOrderRejSub = this._ws.listen('order.rejected')
      .subscribe((data: any) => {
        const order = data.order;
        console.log(order);
        this.onGetOrder(order.id);
      });
  }

  updateDeliverHour() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // January is 0!

    const hour = String(this.delivery_time.hour).padStart(2, '0');
    const minute = String(this.delivery_time.minute).padStart(2, '0');
    const second = String(this.delivery_time.second).padStart(2, '0');

    const fullHour = `${ hour }:${ minute }:${ second }`;

    const fullDateTime = `${ year }-${ month }-${ day } ${ fullHour }`;

    const delivery_time = {
      delivery_time: fullDateTime
    };

    this._orderService.updateOrderHour(this.order.id, delivery_time)
      .subscribe((response: any) => {
        const data = response.data;
        console.log(data);
        const room = data.order.order_id;
        const order = data;

        const payload = {
          order,
          room,
          delivery_hour: fullDateTime
        };

        this._ws.emit('delivery.hour', payload);

        swal({
          title: 'Hora actualizada',
          text: 'La hora de entrega ha sido actualizada',
          type: 'info'
        });
      });
  }

}
