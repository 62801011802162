import { Injectable } from '@angular/core';
import { Cache } from './../util/storage.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { JWT_TOKEN } from './../../config/store';
import { URL_SERVICIOS } from './../../config/url';
import { throwError } from 'rxjs/internal/observable/throwError';
import { catchError } from 'rxjs/operators';

let OneSignal: any;

@Injectable({
  providedIn: 'root'
})
export class OnesignalService {

  private headers: HttpHeaders;

  @Cache({pool: 'OneSignal'}) oneSignalInit: any; // to check if OneSignal is already initialized.
  @Cache({pool: 'OneSignal'}) oneSignalId: any; // store OneSignalId in localStorage
  @Cache({pool: 'Token'}) userSession: any; // User Session management token

  constructor(
    private http: HttpClient
  ) {
    console.log('OneSignal Service Init', this.oneSignalInit);
  }

  public init() {
      this.initOneSignal();
  }

  initOneSignal() {
    OneSignal = window['OneSignal'] || [];
    // console.log(this.oneSignalId);
    OneSignal.sendTag('user_type', 'admin', (tagsSent: any) => {
        // Callback called when tags have finished sending
        console.log('OneSignal Tag Sent', tagsSent);
    });
    console.log('Init OneSignal');
    OneSignal.push(() => {
      OneSignal.init({
        appId: 'f8223f7d-5e67-4d48-be8f-46d548dc3ae6',
        autoRegister: true,
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
            enable: false,
        },
      });
    });
    console.log('OneSignal Initialized');
    this.checkIfSubscribed();
  }

  subscribe() {
    OneSignal.push(() => {
        console.log('Register For Push');
        OneSignal.push(['registerForPushNotifications']);
        OneSignal.on('subscriptionChange', (isSubscribed: any) => {
            console.log('The user\'s subscription state is now:', isSubscribed);
            this.listenForNotification();
            OneSignal.getUserId().then((userId: any) => {
                console.log('User ID is', userId);
                this.oneSignalId = userId;
            });
        });
    });
  }

  listenForNotification() {
    console.log('Initalize Listener');
    OneSignal.on('notificationDisplay', (event: any) => {
        console.log('OneSignal notification displayed:', event);
        this.listenForNotification();
    });
  }

  getUserID() {
    OneSignal.getUserId().then((userId: any) => {
        console.log('User ID is', userId);
        this.oneSignalId = userId;
    });
  }

  checkIfSubscribed() {
    OneSignal.push(() => {
        /* These examples are all valid */
        OneSignal.isPushNotificationsEnabled((isEnabled: any) => {

            if (isEnabled) {
                console.log('Push notifications are enabled!');
                this.getUserID();
            } else {
                console.log('Push notifications are not enabled yet.');
                this.subscribe();
            }
        }, (error: any) => {
            console.log('Push permission not granted');
        });
    });
  }

  updateLocalUserProfile(user_id: number, push_id: Object) {

    // Store OneSignal ID in your server for sending push notificatios.
    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/user-push-id/${ user_id }?token=${ jwtToken }`;

    return this.http.post(url, push_id, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

    /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }
}
