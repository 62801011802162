import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Order } from '../../interfaces/order.interface';
import { URL_SERVICIOS } from 'src/app/config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { JWT_TOKEN } from 'src/app/config/store';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  getAllOrders() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getSpecifiedOrder(order_id: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  storeOrder(order: Order) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders?token=${ jwtToken }`;

    return this.http.post(url, order, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrderProducts(order_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }/products?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrderPromotions(order_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }/promotions?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  updateOrderWithDealer(order_id: number, dealer: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }/dealers?token=${ jwtToken }`;

    return this.http.put(url, dealer, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  updateOrderStatus(order_id: number, order_status: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }/status?token=${ jwtToken }`;

    return this.http.put(url, order_status, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  updateOrderHour(order_id: number, delivery_time: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }/updated-at?token=${ jwtToken }`;

    return this.http.post(url, delivery_time, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getOrderDeliver(dealer_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users/${ dealer_id }?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteOrder(order_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/orders/${ order_id }?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Método para obtener el costo de envio
   *
   * @param jwtToken any
   */
  getServiceCost() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/service-cost?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
