import { Component, OnInit, OnDestroy } from '@angular/core';
import { CategoriesService } from './../../../services/service.index';
import { Category } from './../../../interfaces/category.model';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert2';


@Component({
  selector: 'app-settings-category',
  templateUrl: './settings-category.component.html',
  styleUrls: ['./settings-category.component.css']
})
export class SettingsCategoryComponent implements OnInit, OnDestroy {

  public categories: Category[] = [];
  public settingsCategoryForm: FormGroup;
  public categoriesArray: any[] = [];
  private clearTimeout: any;
  public extraCategory: any;

  constructor(
    public _categoriesService: CategoriesService
  ) {

    const localCategories = this._categoriesService.loadCategories();
    const categoryControls = localCategories.map( () => new FormControl('', Validators.required));

    this.settingsCategoryForm = new FormGroup({
      'categories': new FormArray(categoryControls)
    });
  }

  ngOnInit() {
    this.getAllCategories();
    this.getExtraCategory();
  }

  ngOnDestroy() {
    clearTimeout(this.clearTimeout);
  }

  /**
   * Method to get all categories and fill
   * up the table
   *
   */
  getAllCategories() {
    this._categoriesService.getAllCategories()
      .subscribe((response: any) => {
        const data = response.data;
        this.categories = data;
        this.clearTimeout = setTimeout(() => {
          this.selectProductCategories();
        }, 1000);
      });
  }

  /**
   * Method to use in the ngFor to populate the FormControls
   */
  getCategoriesControls() {
    return (<FormArray>this.settingsCategoryForm.controls['categories']).controls;
  }

  storeCategory() {
    const categories = JSON.stringify(this.categoriesArray);
    const _cat = {
      category: categories
    };
    this._categoriesService.setExtraCategoryProducts(_cat)
      .subscribe((response: any) => {
        console.log(response);
        // const data = response.data;
        // const _product: Product = data;

        swal('¡Guardado!', `La información ha sido guardada en la base de datos`, 'success');
      });

  }
  getExtraCategory() {
    this._categoriesService.getExtraCategoryProducts()
      .subscribe((response: any) => {
        const data = response.data;
        this.extraCategory = data;
      });
  }

  /**
   * Method to store in array the selected categories
   */

  getCustomValue(event: any) {
    const id = parseInt(event.target.value, 10);
    const index = this.categoriesArray.indexOf(id);

    if (event.target.checked) {
      this.categoriesArray.push(id);
      console.log(this.categoriesArray);
    } else {
      if (index > -1) {
        this.categoriesArray.splice(index, 1);
        console.log(this.categoriesArray);
      }
    }

  }

    /**
   * Method to fill the selected categories from product
   */
  selectProductCategories() {

    if (this.extraCategory.length > 0) {
      const categoryArray = this.extraCategory[0].categories;
      const _category = JSON.parse(categoryArray);

      for (let x = 0; x < this.categories.length; x++) {
        let isMatch = false;
        for (let y = 0; y < _category.length; y++) {
          if (this.categories[x].id === _category[y]) {
            this.categoriesArray.push(this.categories[x].id);
            // console.log(this.categoriesArray);
            isMatch = true;
            this.categories[x].checked = true;
          }
        }
        if (!isMatch) {
          this.categories[x].checked = false;
        }
      }
    }
  }

}
