import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from './../../config/url';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { JWT_TOKEN, USER_STORAGE } from '../../config/store';
import { User } from '../../interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private headers: HttpHeaders;
  user: User;
  token: string;

  constructor(
    private http: HttpClient
  ) {
    console.log('User Service');
    this.loadStorage();
  }

  loadStorage() {
    if (localStorage.getItem(JWT_TOKEN)) {
      this.token = localStorage.getItem(JWT_TOKEN);
      this.user = JSON.parse(localStorage.getItem(USER_STORAGE));
    } else {
      this.user = null;
      this.token = '';
    }
  }
  /**
   * Function to get all users for an API
   */
  getAllUsers() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users?token=${ jwtToken }`;
    return this.http.get( url, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Store a new user
   */
  storeNewUser(user: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users?token=${ jwtToken }`;
    return this.http.post( url, user, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
   * Function to delete a specified user
   *
   * @param user_id user id
   */
  deleteUser(user_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users/${ user_id }?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Get the specified user
   *
   * @param user_id
   */
  getEspecifiedUser(user_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users/${ user_id }?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Function to update a specified user
   *
   * @param value     form value
   * @param user_id   user id
   */
  updateUser(value: any, user_id: number ) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/users/${ user_id }?token=${ jwtToken }`;

    return this.http.patch(url, value, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to get all dealers
   */
  getDealers() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/dealers?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Método para traer la información de un repartidor
   * en especifico
   *
   */
  getDealer(dealer_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/dealers/${ dealer_id }?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  getAuthUser() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/get-user?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
