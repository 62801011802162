import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SettingsService } from '../../../services/settings/settings.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.css']
})
export class SettingsGeneralComponent implements OnInit {

  serviceCostForm: FormGroup;
  minBuyForm: FormGroup;

  constructor(
    private _settings: SettingsService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getServiceCost();
    this.getMinBuy();
  }

  initForm() {
    this.serviceCostForm = new FormGroup({
      service_cost: new FormControl(0, Validators.min(1))
    });

    this.minBuyForm = new FormGroup({
      minBuy: new FormControl(0, Validators.min(1))
    });
  }

  getServiceCost() {

    this._settings.getServiceCost()
      .subscribe((response: any) => {
        const data = response.data;
        const service = data[0];

        this.serviceCostForm.get('service_cost').setValue(service.service_cost);
      });
  }

  getMinBuy() {

    this._settings.getMinBuy()
      .subscribe((response: any) => {
        const data = response.data;
        const min = data[0];

        this.minBuyForm.get('minBuy').setValue(min.min_buy);
      });
  }

  sendServiceCostForm(value: any) {

    this._settings.updateServiceCost(value)
      .subscribe(() => {
        swal('¡Actualizado!', 'El costo del servicio ha sido actualizado', 'success');
      });
  }

  sendMinBuyForm(value: any) {

    this._settings.updateMinBuy(value)
    .subscribe(() => {
      swal('¡Actualizado!', 'El precio mínimo de compra ha sido actualizado', 'success');
    });
  }

}
