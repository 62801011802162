import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  menu: any = [
    {
      title: 'Escritorio',
      icon: 'mdi mdi-gauge',
      submenu: [
        { title: 'Escritorio', url: '/escritorio' },
        { title: 'graficas', url: '/graficas' },
      ],
    },
    {
      title: 'Pedidos',
      icon: 'mdi mdi-calendar-clock',
      submenu: [
        { title: 'Todos los pedidos', url: '/pedidos' },
      ],
    },
    {
      title: 'Promociones',
      icon: 'mdi mdi-ticket',
      submenu: [
        {  title: 'Todas las promociones', url: '/promociones'},
        { title: 'Añadir promoción', url: '/nueva-promocion' }
      ],
    },
    {
      title: 'Productos',
      icon: 'mdi mdi-food-fork-drink',
      submenu: [
        { title: 'Todos los productos', url: '/productos' },
        { title: 'Añadir producto', url: '/nuevo-producto' },
        { title: 'Categorías', url: '/categorias' },
        { title: 'Generar reporte', url: '/reporte-catalogo' },
        // { title: 'Atributos', url: '/atributos' },
      ],
    },
    {
      title: 'Usuarios',
      icon: 'mdi mdi-account',
      submenu: [
        { title: 'Todos los usuarios', url: '/usuarios' },
        { title: 'Añadir Nuevo', url: '/nuevo-usuario' }
      ],
    },
    {
      title: 'Ajustes',
      icon: 'mdi mdi-settings',
      submenu: [
        { title: 'Lugares', url: '/lugares' },
        { title: 'Precios', url: '/precios' },
        { title: 'Ajustes Categoría', url: '/ajustes-categoria' },
        { title: 'Ajustes Generales', url: '/ajustes-general' },
      ],
    }
  ];

  constructor() { }
}
