import { Routes, RouterModule } from '@angular/router';

import { PagesComponent } from './pages.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GraficasComponent } from './graficas/graficas.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { DetailOrderComponent } from './orders/detail-order.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { NewPromotionComponent } from './promotions/new-promotion.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { NewProductComponent } from './catalogo/new-product.component';
import { CategoriesComponent } from './catalogo/categories/categories.component';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './users/new-user.component';
import { EditUserComponent } from './users/edit-user.component';
import { EditProductComponent } from './catalogo/edit-product.component';
import { EditCategoryComponent } from './catalogo/categories/edit-category.component';
import { EditPromotionComponent } from './promotions/edit-promotion.component';
import { SettingPlacesComponent } from './settings/setting-places.component';
import { SettingPricesComponent } from './settings/setting-prices.component';
import { SettingsCategoryComponent } from './settings/settings-category/settings-category.component';
import { SettingsGeneralComponent } from './settings/settings-general/settings-general.component';
import { CatalogoReportComponent } from './catalogo/catalogo-report/catalogo-report.component';
import { LoginGuard } from '../services/guards/login.guard';

const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [LoginGuard],
        children: [
            // Profile paths
            { path: 'perfil', component: ProfileComponent, data: { titulo: 'Perfil' } },
            { path: 'account-settings', component: AccountSettingsComponent, data: { titulo: 'Ajustes de cuenta' } },
            // Dashboard paths
            { path: 'escritorio', component: DashboardComponent, data: { titulo: 'Escritorio' } },
            { path: 'graficas', component: GraficasComponent, data: { titulo: 'Graficas' } },
            // Orders paths
            { path: 'pedidos', component: OrdersComponent, data: { titulo: 'Pedidos' } },
            { path: 'detalle-orden/:id', component: DetailOrderComponent, data: { titulo: 'Detalle Orden' } },
            // Promotion paths
            { path: 'promociones', component: PromotionsComponent, data: { titulo: 'Promociones' } },
            { path: 'nueva-promocion', component: NewPromotionComponent, data: { titulo: 'Nueva Promoción' } },
            { path: 'promocion/:id', component: EditPromotionComponent, data: { titulo: 'Editar Promoción' } },
            // Catalog paths
            { path: 'productos', component: CatalogoComponent, data: { titulo: 'Catálogo' } },
            { path: 'producto/:id', component: EditProductComponent, data: { titulo: 'Catálogo' } },
            { path: 'nuevo-producto', component: NewProductComponent, data: { titulo: 'Nuevo Producto' } },
            { path: 'categorias', component: CategoriesComponent, data: { titulo: 'Categorías' } },
            { path: 'categoria/:id', component: EditCategoryComponent, data: { titulo: 'Editar Categoría' } },
            { path: 'reporte-catalogo', component: CatalogoReportComponent, data: { titulo: 'Generar reporte' } },
            // User paths
            { path: 'usuarios', component: UsersComponent, data: { titulo: 'Usuarios' } },
            { path: 'usuario/:id', component: EditUserComponent, data: { titulo: 'Editar Usuario' } },
            { path: 'nuevo-usuario', component: NewUserComponent, data: { titulo: 'Nuevo Usuario' } },
            // Settings paths
            { path: 'lugares', component: SettingPlacesComponent, data: { titulo: 'Ajustes de entrega' } },
            { path: 'precios', component: SettingPricesComponent, data: { titulo: 'Ajustes de precios' } },
            { path: 'ajustes-categoria', component: SettingsCategoryComponent, data: { titulo: 'Ajustes Categoría' } },
            { path: 'ajustes-general', component: SettingsGeneralComponent, data: { titulo: 'Ajustes Generales' } },
            { path: '', redirectTo: '/escritorio', pathMatch: 'full' },
        ]
    },
];
export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes);
