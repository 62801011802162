import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService, SubirArchivoService } from '../../services/service.index';

import swal from 'sweetalert2';
import { User } from '../../interfaces/user.interface';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  newUserF: FormGroup;
  loading =  false;
  showDealerFields = false;
  prevImg: string | ArrayBuffer;
  userImage: File;

  constructor(
    public _usersService: UsersService,
    private _uploadFileService: SubirArchivoService
  ) {

    /**
     * Form init
     */
    this.newUserF = new FormGroup({
      'name': new FormControl('', Validators.required),
      'last_name': new FormControl('', Validators.required),
      'birthdate': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'phone': new FormControl('', Validators.required),
      'password': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'password_confirmation': new FormControl(''),
      'role': new FormControl('', Validators.required),
      'number_plates': new FormControl('')
    });

    this.newUserF.controls['password_confirmation'].setValidators([
      Validators.required,
      Validators.minLength(6),
      this.noIgual.bind(this.newUserF)
    ]);

  }

  /**
   * Validator que compara los dos password para saber si son iguales o no
   */
  noIgual(control: FormControl): {[s: string]: boolean} {
    const form: any = this;

    if (control.value !== form.controls['password'].value) {
      return {
        noiguales: true
      };
    }
    return null;
  }

  /**
   * Function to store a new user
   *
   * @param user
   */
  storeNewUser(user: any) {
    this.loading = true;
    this._usersService.storeNewUser(user)
    .subscribe((response: any) => {

        const data = response.user;
        const $user: User = data;
        console.log($user);
        // Guardar la imágen
        this._uploadFileService.uploadFile(this.userImage, 'usuarios', $user.id, '', '', '')
        .then((resp: any) => {
          console.log(resp);
          const _data = resp.data;
          const _user: User = _data;
          swal('Imágen Actualizada', _user.name, 'success');
        })
        .catch(error => console.error(error));

        this.loading = false;
        this.newUserF.reset();
        this.prevImg = null;
        swal('Nuevo Usuario', `El usuario ${ $user.name } se creo correctamente`, 'success');
      }, error => {
        console.log(error);
        this.loading = false;
        if (error.error.email) {
          swal('Error', `${error.error.email[0]}`, 'error');
        } else if (error.error.phone) {
          swal('Error', `${error.error.phone[0]}`, 'error');
        }
      });
  }

  ngOnInit() {
  }

  checkRole(value: string) {

    if (value === '2') {
      console.log('repartidor', value);
      this.showDealerFields = true;

      this.newUserF.controls['number_plates'].setValidators([
        Validators.required
      ]);

    } else {
      this.newUserF.get('number_plates').setValue(null);
      console.log('los demas', value);
      this.showDealerFields = false;
    }
  }

  feedbackImage(image: File) {

    if (!image) {
      this.userImage = null;
      return;
    }

    if ( image.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.userImage = null;
      return;
    }
    this.userImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

}
