import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoginService, UsersService } from '../../services/service.index';
import { User } from '../../interfaces/user.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {

  usuario: User;

  constructor(
    public _login: LoginService,
    public _user: UsersService
  ) { }

  ngOnInit() {
    this.usuario = this._user.user;
    console.log(this.usuario);
  }

}
