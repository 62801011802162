import { USER_STORAGE } from './../../config/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsService, DataTableService } from './../../services/service.index';
import { Product } from './../../interfaces/product.model';

import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html',
  styleUrls: ['./catalogo.component.css']
})
export class CatalogoComponent implements OnInit, OnDestroy {

  public products: Product[] = [];
  public productTable: any;
  status_sold_out: string;
  private productSubscription: Subscription;

  constructor(
    public _productsService: ProductsService,
    private _dataTableService: DataTableService
  ) {
    this.status_sold_out = '1';
  }

  ngOnInit() {
    this.getProducts();
  }
  ngOnDestroy() {
    this.productSubscription.unsubscribe();
  }

  /**
   * Function to get all producto, using API
   */
  getProducts() {
    this.productSubscription = this._productsService.getAllProducts()
      .subscribe( (response: any) => {
        const data = response.data;
        this.products = data;
        this._dataTableService.reInitTable();
      }, error => {
        console.error(error);
      });
  }

  deleteProduct(product: Product) {
    swal({
      title: 'Borrar Producto',
      text: `¿Quieres borrar el producto: ${ product.name }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        const user = JSON.parse(localStorage.getItem(USER_STORAGE));
        const seller_id = user.id;
        this._productsService.deleteSellerProduct(product, seller_id)
          .subscribe((response: any) => {
            const data = response.data;
            swal('¡Borrado!', `El producto ${ data.name } ha sido borrado`, 'success');
          }, error => {
            swal('¡Error!', error.error, 'error');
            console.error(error);
          });
      }
    });
  }

}
