import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { User } from './../../interfaces/user.interface';
import { UsersService, DataTableService } from './../../services/service.index';

import swal from 'sweetalert2';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  private userSubscription: Subscription;
  public users: User[] = [];
  public table: any;

  constructor(
    public _userService: UsersService,
    private _dataTableService: DataTableService
  ) {}

  ngOnInit() {
    this.cargarUsuarios();
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }
  /**
   * Function to get all the users
   */
  cargarUsuarios() {
    this.userSubscription = this._userService.getAllUsers()
    .subscribe((response: any) => {
      this.users = response.data;

      this._dataTableService.reInitTable();
      });
  }

  /**
   * Function to delete a user
   *
   * @param user_id number  user id
   */
  deleteUser(user: User) {
    console.log(user);
    swal({
      title: 'Borrar usuario',
      text: `¿Quieres borrar al usuario: ${ user.name }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this._userService.deleteUser(user.id)
          .subscribe((response: any) => {
            console.log(response);
            this.cargarUsuarios();
            swal('¡Borrado!', `El usuario ${ response.data.name } ha sido borrado`, 'success');
          });
      }
    });
  }

}
