import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from './../../config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Promotion } from './../../interfaces/promotion.model';
import { JWT_TOKEN } from 'src/app/config/store';

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  /**
   * Method to get all promotions
   *
   */
  getAllPromotions() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/promotions?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to save a promotion en DB
   *
   * @param promotion Promotion
   */
  storePromotion(promotion: Promotion) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/promotions?token=${ jwtToken }`;

    return this.http.post(url, promotion, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to show a specified promotion
   *
   * @param promotion_id number
   */
  showPromotion(promotion_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/promotions/${ promotion_id }?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Method to edit a specified promotion
   *
   * @param promotion Promotion
   * @param promotion_id number
   */
  editPromotion(promotion: Promotion, promotion_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/promotions/${ promotion_id }?token=${ jwtToken }`;

    return this.http.put(url, promotion, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  deletePromotion(promotion: Promotion) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/promotions/${ promotion.id}?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
