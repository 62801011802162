import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// Form Module
import { FormsModule } from '@angular/forms';

// HttpClientModule
import { HttpClientModule } from '@angular/common/http';

// Sockets
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = {
  url: environment.socketIO,
  options: {}
};

/**
 * Archivo TS con las rutas de la aplicación
 */
import { APP_ROUTES } from './app.routes';

// Services
import { ServiceModule } from './services/service.module';

 // PagesModule
 import { PagesModule } from './pages/pages.module';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './login/signup.component';
import { RecoverPasswordComponent } from './login/recover-password.component';
import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    RecoverPasswordComponent
  ],
  imports: [
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    ServiceModule,
    HttpClientModule,
    FormsModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
