import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionsService, SubirArchivoService } from './../../services/service.index';
import { Promotion } from '../../interfaces/promotion.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbCalendar, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-promotion',
  templateUrl: './edit-promotion.component.html',
  styleUrls: ['./edit-promotion.component.css']
})
export class EditPromotionComponent implements OnInit {

  public promotion_id: number;
  public editPromotionForm: FormGroup;
  public prevImg: string | ArrayBuffer;
  public promotionImage: File;
  public promotion: Promotion;

  // Datepicker variables
  public hoveredDate: NgbDateStruct;
  public fromDate: NgbDateStruct;
  public toDate: NgbDateStruct;
  public start_time: NgbTimeStruct;
  public end_time: NgbTimeStruct;

  constructor(
    private activatedRoute: ActivatedRoute,
    public _promotionService: PromotionsService,
    private _uploadFileService: SubirArchivoService,
    private router: Router,
    public calendar: NgbCalendar,
  ) {
    this.getUrlParam();
  }

  ngOnInit() {
    this.initForm();
    this.onGetProduct(this.promotion_id);
  }

  getUrlParam() {
    this.activatedRoute.params.subscribe(params => {
      this.promotion_id = params['id'];
    });
  }

  onGetProduct(promotion_id: number) {
    this._promotionService.showPromotion(promotion_id)
        .subscribe((response: any) => {
          const data = response.data;
          const promotion: Promotion = data;
          this.promotion = promotion;

          /**
           * Start Date
           */

          const start_date = promotion.start_date;
          const start_date_string = start_date.split(' ');

          const startFullDate = start_date_string[0].split('-');
          const startFullTime = start_date_string[1].split(':');

          const start_year = parseInt(startFullDate[0], 10);
          const start_month = parseInt(startFullDate[1], 10);
          const start_day = parseInt(startFullDate[2], 10);

          const start_hour = parseInt(startFullTime[0], 10);
          const start_minute = parseInt(startFullTime[1], 10);
          const start_second = parseInt(startFullTime[2], 10);

          /**
           * End Date
           */

          const end_date = promotion.end_date;
          const end_date_string = end_date.split(' ');

          const endFullDate = end_date_string[0].split('-');
          const endFullTime = end_date_string[1].split(':');

          const end_year = parseInt(endFullDate[0], 10);
          const end_month = parseInt(endFullDate[1], 10);
          const end_day = parseInt(endFullDate[2], 10);

          const end_hour = parseInt(endFullTime[0], 10);
          const end_minute = parseInt(endFullTime[1], 10);
          const end_second = parseInt(endFullTime[2], 10);

          this.fromDate = {year: start_year, month: start_month, day: start_day};
          this.toDate = {year: end_year, month: end_month, day: end_day};
          this.start_time = {hour: start_hour, minute: start_minute, second: start_second};
          this.end_time = {hour: end_hour, minute: end_minute, second: end_second};

          this.setFormValue(promotion);
        }, error => console.error(error));
  }

  setFormValue(promotion: Promotion) {
    this.editPromotionForm.get('name').setValue(promotion.name);
    this.editPromotionForm.get('description').setValue(promotion.description);
    this.editPromotionForm.get('price').setValue(promotion.price);
  }

  initForm() {
    this.editPromotionForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'price': new FormControl('', Validators.required),
    });
  }

  updatePromotion(promotion: Promotion) {
    if (this.fromDate && this.toDate) {
      promotion.start_date = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' ';
      promotion.start_date += this.start_time.hour + ':' + this.start_time.minute + ':' + this.start_time.second;
      promotion.end_date = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' ';
      promotion.end_date += this.end_time.hour + ':' + this.end_time.minute + ':' + this.end_time.second;
    }
    this._promotionService.editPromotion(promotion, this.promotion_id)
      .subscribe((response: any) => {
        const data = response.data;
        const _promotion: Promotion = data;
        swal('¡Actualizado!', `La promoción ${ _promotion.name } ha sido actualizada`, 'success')
          .then((result) => {
            if (result.value) {
              this.router.navigate(['/promociones']);
            }
          });
      }, error => {
        console.log(error);
        if (error.code === 422) {
          swal('¡Error!', error.error, 'error');
        }
      });
  }

  feedbackImage(image: File) {
    if (!image) {
      this.promotionImage = null;
      return;
    }

    if (image.type.indexOf('image') < 0) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.promotionImage = null;
      return;
    }
    this.promotionImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  uploadImage() {
    this._uploadFileService.uploadFile(this.promotionImage, 'promociones', '', '', '', this.promotion_id)
      .then((response: any) => {
        const data = response.data;
        const _promotion: Promotion = data;
        swal('Imágen Actualizada', _promotion.name, 'success');
      })
      .catch(error => console.error(error));
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  /**
   * Methods to interact with the calendar
   */
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}
