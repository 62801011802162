import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Category } from '../../../interfaces/category.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesService, SubirArchivoService } from './../../../services/service.index';

import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent implements OnInit, OnDestroy {

  public editCategoryForm: FormGroup;
  public category_id: number;
  public prevImg: string | ArrayBuffer;
  public categoryImage: File;
  public category: Category;

  @ViewChild('categoryImage') _categoryImage: ElementRef;

  constructor(
    public _categoriesService: CategoriesService,
    private activatedRoute: ActivatedRoute,
    private _uploadFileService: SubirArchivoService,
    private router: Router
  ) {
    this.getUrlParam();
  }

  ngOnInit() {
    this.initForm();
    this.onGetCategory();
  }

  ngOnDestroy() {
    // this.onGetCategorySub.unsubscribe();
  }

  initForm() {
    this.editCategoryForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'image': new FormControl()
    });
  }

  /**
   * Method for handle the form submit event
   *
   * @param category any
   */
  editCategory(category: any) {
    this._categoriesService.updateCategory(category, this.category_id)
      .subscribe((response: any) => {
        const data = response.data;
        const _category: Category = data;
        swal('Actualizado', `La categoría ${ _category.name } ha sido actualizada`, 'success')
          .then((result) => {
            if (result.value) {
              this.router.navigate(['/categorias']);
            }
          });
      }, error => {
        console.error(error);
        if (error.error) {
          swal('¡Error!', error.error, 'error');
        }
      });
  }

  setFormValues(category: Category) {
    this.editCategoryForm.get('name').setValue(category.name);
    this.editCategoryForm.get('description').setValue(category.description);
  }

  /**
   * Method to load the category based on the
   * id from the url
   *
   */
  onGetCategory() {
    this._categoriesService.getSpecifiedCategory(this.category_id)
      .subscribe( (response: any ) => {
        const data = response.data;
        const category: Category = data;
        this.category = category;
        console.log(category);
        this.setFormValues(category);
      });
  }

  /**
   * Function to get the product id from de url
   */
  getUrlParam() {
    this.activatedRoute.params.subscribe( params => {
      this.category_id = params['id'];
    });
  }

  feedbackImage(image: File) {

    if (!image) {
      this.categoryImage = null;
      return;
    }

    if ( image.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.categoryImage = null;
      return;
    }
    this.categoryImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  uploadImage() {
    this._uploadFileService.uploadFile(this.categoryImage, 'categorias', '', '', this.category_id, '')
      .then((response: any) => {
        console.log(response);
        const data = response.data;
        const _category: Category = data;
        swal('Imágen Actualizada', _category.name, 'success')
          .then((result) => {
            if (result.value) {
              this._categoryImage.nativeElement.value = '';
            }
          });
      })
      .catch(error => console.error(error));
  }

}
