import { Component, OnInit } from '@angular/core';
import { Product } from './../../../interfaces/product.model';
import { ProductsService, DataTableService } from './../../../services/service.index';

@Component({
  selector: 'app-catalogo-report',
  templateUrl: './catalogo-report.component.html',
  styleUrls: ['./catalogo-report.component.css']
})
export class CatalogoReportComponent implements OnInit {

  products: Product[] = [];
  status_sold_out: string;

  constructor(
    public _productsService: ProductsService,
    private _dataTableService: DataTableService
  ) {
    this.status_sold_out = '1';
  }

  ngOnInit() {
    this.getProducts();
  }

  /**
   * Function to get all producto, using API
   */
  getProducts() {
    this._productsService.getAllProducts()
      .subscribe( (response: any) => {
        const data = response.data;
        this.products = data;
        this._dataTableService.reInitReportTable();
      }, error => {
        console.error(error);
      });
  }

}
