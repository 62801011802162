import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  SettingsService,
  SharedService,
  SidebarService,
  UsersService,
  ProductsService,
  CategoriesService,
  DataTableService,
  PromotionsService,
  SubirArchivoService,
  OrdersService,
  LoginGuard,
  LoginService,
  WebsocketService,
  DashboardService,
  OnesignalService,
  DeliverService,
  AuthInterceptor,
  TokenInterceptor
 } from './service.index';

@NgModule({
  imports: [
    CommonModule
  ],
  providers: [
    SettingsService,
    SharedService,
    SidebarService,
    UsersService,
    ProductsService,
    CategoriesService,
    DataTableService,
    PromotionsService,
    SubirArchivoService,
    OrdersService,
    LoginGuard,
    LoginService,
    WebsocketService,
    DashboardService,
    OnesignalService,
    DeliverService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],
  declarations: []
})
export class ServiceModule { }
