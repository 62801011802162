import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrdersService, DataTableService } from './../../services/service.index';
import { WebsocketService } from '../../services/websocket/websocket.service';
import { Router } from '@angular/router';
import { USER_STORAGE } from './../../config/store';

import swal from 'sweetalert2';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit, OnDestroy {

  public orders: any;
  public dataTable: any;
  private orderSubs: Subscription;
  private listenSubs: Subscription;
  private listenClientOrderCancel: Subscription;

  constructor(
    public _orderService: OrdersService,
    private _dataTableService: DataTableService,
    private _ws: WebsocketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.showAllOrders();
    this.listenEvent();
  }

  ngOnDestroy() {
    this.orderSubs.unsubscribe();
    this.listenSubs.unsubscribe();
  }

  showAllOrders() {
    this.orderSubs = this._orderService.getAllOrders()
      .subscribe((response: any) => {
        const data = response.data;
        this.orders = data;
        // console.log(this.orders);

        this._dataTableService.reInitTable();
      }, error => console.error(error));
  }

  listenEvent() {
    this.listenSubs = this._ws.listen('order.status_new').subscribe((response: any) => {
      console.log(response);
      this.showAllOrders();
    });

    this.listenClientOrderCancel = this._ws.listen('admin:client.cancel.order').subscribe((data_resp: any) => {
      this.showAllOrders();
    });
  }

  deleteOrder(order: any) {
    swal({
      title: 'Borrar orden',
      text: `¿Quieres borrar la orden No: ${ order.order_id }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result: any) => {
      if (result.value) {
        this._orderService.deleteOrder(order.id)
          .subscribe((response: any) => {
            console.log(response);
            this.showAllOrders();
            swal('¡Borrado!', `La orden ${ response.data.order_id } ha sido borrado`, 'success');
          });
      }
    });
  }

  goSetRoom(order: any) {
    console.log(order);

    if (localStorage.getItem(USER_STORAGE)) {

      const user = JSON.parse(localStorage.getItem(USER_STORAGE));
      const completeName = `${ user.name } ${ user.last_name }`;
      const order_id = order.order_id;

      this._ws.loginSockets(completeName, 'admin', order_id);
    }
  }

}
