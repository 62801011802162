import { Injectable } from '@angular/core';
import { WebsocketService } from '../websocket/websocket.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {

    constructor(
        private _ws: WebsocketService
    ) {}

    getActiveUsers() {
        return this._ws.listen('usuarios-activos');
    }

    emitActiveUsers() {
        this._ws.emit('obtener-usuarios');
      }
}
