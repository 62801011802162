import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductsService } from './../../services/service.index';
import { Product } from './../../interfaces/product.model';
import { CategoriesService, SubirArchivoService } from '../../services/service.index';
import { Category } from './../../interfaces/category.model';
import { Subscription } from 'rxjs';
import { USER_STORAGE } from './../../config/store';

import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent implements OnInit, OnDestroy {

  public editProductForm: FormGroup;
  private product_id: number;
  public product: Product;
  public products: Product[] = [];
  public product_type: any;
  public categories: any[] = [];
  public categoriesArray: any[] = [];
  public product_categories: Category[] = [];
  public categoryControls: FormControl[];
  private categorySub: Subscription;
  private productSub: Subscription;
  private productsSub: Subscription;
  private productCategorySub: Subscription;
  private clearTimeout: any;
  public childrens: any[] = [];
  public dropdownSettings = {};
  public prevImg: string | ArrayBuffer;
  public productImage: File;

  constructor(
    public _productsService: ProductsService,
    public _categoriesService: CategoriesService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public _uploadService: SubirArchivoService
  ) {
    this.getUrlParam();

  }

  ngOnInit() {

    const localCategories = this._categoriesService.loadCategories();
    const categoryControls = localCategories.map( () => new FormControl(false));

    this.editProductForm = new FormGroup({
      'sku': new FormControl(''),
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'is_featured': new FormControl(false),
      'price': new FormControl(1),
      // 'sales_price': new FormControl('0'),
      'cost': new FormControl(0),
      'content': new FormControl(),
      'sold_out': new FormControl('0'), // '0' = Stock, '1' = sold_out
      'quantity': new FormControl('0'),
      'status': new FormControl(true),
      'categories': new FormArray(categoryControls)
    });

    this.product_type = '1';
    this.cargarProductos();
    this.initDropdown();
    this.onGetProduct(this.product_id);
  }

  ngOnDestroy() {
    this.categorySub.unsubscribe();
    this.productSub.unsubscribe();
    this.productCategorySub.unsubscribe();
    clearTimeout(this.clearTimeout);
  }

  /**
   * Function to load all the products
   */
  cargarProductos() {
    this.productsSub = this._productsService.getAllProducts()
      .subscribe( (response: any) => {
        const data =  response.data;
        this.products = data;
        // console.log(this.products);
      });
  }

  showPanel(value: string) {
    console.log(value);
    this.product_type = value;
  }

  /**
   * Method to use in the ngFor to populate the FormControls
   */
  getCategoriesControls() {
    return (<FormArray>this.editProductForm.controls['categories']).controls;
  }
  /**
   * Settings to ng-multiselect-dropdown
   */

  initDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }

  /**
   * Method to store in array the selected categories
   */

  getCustomValue(event: any) {
    const id = parseInt(event.target.value, 10);
    const index = this.categoriesArray.indexOf(id);

    if (event.target.checked) {
      this.categoriesArray.push(id);
      console.log(this.categoriesArray);
    } else {
      if (index > -1) {
        this.categoriesArray.splice(index, 1);
        console.log(this.categoriesArray);
      }
    }

  }

  /**
   * Method to fill the selected categories from product
   */
  selectProductCategories() {
    for (let x = 0; x < this.categories.length; x++) {
      let isMatch = false;
      for (let y = 0; y < this.product_categories.length; y++) {
        if (this.categories[x].id === this.product_categories[y].id) {
          this.categoriesArray.push(this.categories[x].id);
          // console.log(this.categoriesArray);
          isMatch = true;
          this.categories[x].checked = true;
        }
      }
      if (!isMatch) {
        this.categories[x].checked = false;
      }
    }
  }

  /**
   * Function to handle the checkbox is featured
   *
   * @param target any
   */
  isFeatured(target: any) {

    if (target.checked) {
      this.editProductForm.get('is_featured').setValue(true);
    } else {
      this.editProductForm.get('is_featured').setValue(false);
    }
  }

  /**
   *  Function to get the of sold_out checkbox
   * @param sold_out any
   */
  getSoldOut(sold_out: any) {
    this.editProductForm.get('sold_out').setValue(sold_out);
  }

  /**
   * Get the checkbox status
   *
   * @param status any
   */
  onStatus(status: any) {
    if (status.checked) {
      this.editProductForm.get('status').setValue(true);
    } else {
      this.editProductForm.get('status').setValue(false);
    }
  }

  /**
   * Method to get the asociated category from specified product
   *
   */
  onGetProductCategory() {
    this.productCategorySub = this._categoriesService.getProductCategory(this.product_id)
      .subscribe((response: any) => {
        const data = response.data;
        this.product_categories = data;
        // console.log(this.product_categories);
      });
  }

  /**
   * Method to fill up the form with the selected
   * product
   *
   * @param product_id number
   */
  onGetProduct(product_id: number) {
    this.productSub = this._productsService.getSpecifiedProduct(product_id)
      .subscribe((response: any) => {
        const data = response.data;
        const product = data;
        this.product = product;
        // console.log(this.product);
        if (product.childrens.length > 0) {
          this.childrens = JSON.parse(product.childrens);
          // console.log(this.childrens);
          if (this.childrens.length > 0) {
            this.product_type = '2';
          } else {
            this.product_type = '1';
          }
        }
        this.setFormValues(product);
        this.onGetProductCategory();
        this.clearTimeout = setTimeout(() => this.getCategories(), 1000);
      });
  }

/**
 * Method to fill the form with the product info
 *
 * @param product Product
 */
  setFormValues(product: Product) {
    this.editProductForm.get('sku').setValue(product.sku);
    this.editProductForm.get('name').setValue(product.name);
    this.editProductForm.get('description').setValue(product.description);

    if (product.is_featured === '1') {
      this.editProductForm.get('is_featured').setValue(true);
    } else {
      this.editProductForm.get('is_featured').setValue(false);
    }

    this.editProductForm.get('price').setValue(product.price);
    // this.editProductForm.get('sales_price').setValue(product.sales_price);
    this.editProductForm.get('cost').setValue(product.cost);
    this.editProductForm.get('sold_out').setValue(product.sold_out);
    this.editProductForm.get('quantity').setValue(product.quantity);
    this.editProductForm.get('content').setValue(product.content);

    if (product.status === '1') {
      this.editProductForm.get('status').setValue(true);
    } else {
      this.editProductForm.get('status').setValue(false);
    }

  }

  /**
   * Method to get all categories
   */
  getCategories() {
    this.categorySub = this._categoriesService.getAllCategories()
    .subscribe((response: any) => {
      const data = response.data;
      this.categories = data;
      // console.log(this.categories);
      this._categoriesService.storeCategories(this.categories);
      this.clearTimeout = setTimeout(() => {
        this.selectProductCategories();
      }, 1000);
    });
  }

  /**
   * Method to handle the form submit event
   *
   * @param product any
   */
  updateProduct(product: any) {

    if (this.childrens.length > 0) {
      product.childrens = JSON.stringify(this.childrens);
    }
    product.categories = this.categoriesArray;

    if (product.is_featured) {
      product.is_featured = '1';
      // console.log(product.is_featured);
    } else {
      product.is_featured = '0';
    }
    if (product.status) {
      product.status = '1';
    } else {
      product.status = '0';
    }
    // console.log(product);


    if (localStorage.getItem(USER_STORAGE)) {

      const user = JSON.parse(localStorage.getItem(USER_STORAGE));

      this._productsService.updateSellerProduct(product, this.product_id, user.id)
        .subscribe((response: any) => {
          const data = response.data;
          const _product: Product = data;
          swal('Actualizado', `El producto ${ _product.name } ha sido actualizado`, 'success')
            .then((result) => {
              if (result.value) {
                this.router.navigate(['/productos']);
              }
            });
        }, error => {
          console.log(error);
          swal('Error', error.error, 'error');
          if (error.error.status || error.error.is_featured) {
            swal('Error', `${ error.error.status[0] }, ${ error.error.is_featured[0] }`, 'error');
          }
        });
    }
  }

  /**
   * Function to get the product id from de url
   */
  getUrlParam() {
    this.activatedRoute.params.subscribe( params => {
      this.product_id = params['id'];
    });
  }

  /**
   * Function to catch the selected item
   *
   * @param item
   */
  onItemSelect(item: any) {
    this.childrens.push(item);
    const duplicate = this.childrens.filter(itemP => itemP.id === item.id);
    if (duplicate.length > 1) {
      this.childrens.pop();
    }
    this.childrens.map(itemX => itemX.price = 0);
    console.log(this.childrens);
    this._productsService.storeChildrens(JSON.stringify(this.childrens));
  }

  /**
   * Function to filter the selected products
   * and delete form the array
   *
   * @param item any
   */
  onItemDeselect(item: any) {
    this.childrens = this.childrens.filter(product => product.id !== item.id);
    console.log(this.childrens);
    this._productsService.storeChildrens(JSON.stringify(this.childrens));
  }

  feedbackImage(image: File) {
    if (!image) {
      this.productImage = null;
      return;
    }

    if (image.type.indexOf('image') < 0) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.productImage = null;
      return;
    }
    this.productImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  uploadImage() {
    this._uploadService.uploadFile(this.productImage, 'productos', '', this.product_id, '', '')
      .then((response: any) => {
        const data = response.data;
        const _product: Product = data;
        swal('Imágen Actualizada', _product.name, 'success');
      })
      .catch(error => console.error(error));
  }

}
