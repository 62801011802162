import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductsService } from './../../services/service.index';
import { Product } from '../../interfaces/product.model';
import { Subscription } from 'rxjs';
import { PromotionsService, SubirArchivoService } from './../../services/service.index';
import { Promotion } from './../../interfaces/promotion.model';
import { NgbDate, NgbCalendar, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import swal from 'sweetalert2';

@Component({
  selector: 'app-new-promotion',
  templateUrl: './new-promotion.component.html',
  styleUrls: ['./new-promotion.component.css']
})
export class NewPromotionComponent implements OnInit, OnDestroy {

  public newPromotionForm: FormGroup;
  public products: Product[] = [];
  private productSubs: Subscription;
  public productsArray: any[] = [];
  public promotionImage: File;
  public prevImg: string | ArrayBuffer;

  // Datepicker variables
  public hoveredDate: NgbDate;
  public fromDate: NgbDate;
  public toDate: NgbDate;
  public start_time: NgbTimeStruct;
  public end_time: NgbTimeStruct;

  constructor(
    public _productsService: ProductsService,
    public _promotionService: PromotionsService,
    public _uploadFileService: SubirArchivoService,
    public calendar: NgbCalendar,
    private router: Router
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 5);
    this.start_time = {hour: 0, minute: 0, second: 0};
    this.end_time = {hour: 0, minute: 0, second: 0};
  }

  ngOnInit() {
    this.onGetProducts();
    this.initForm();
  }

  ngOnDestroy() {
    this.productSubs.unsubscribe();
  }

  initForm() {

    this.newPromotionForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'price': new FormControl('', Validators.required),
      'image': new FormControl(''),
    });
  }

  onGetProducts() {
    this.productSubs = this._productsService.getAllProducts()
      .subscribe((response: any) => {
        const data = response.data;
        this.products = data;
        this._productsService.storeProducts(this.products);
        // console.log(this.products);
      });
  }

  createPromotion(promotion: Promotion) {
    if (this.fromDate && this.toDate) {
      promotion.start_date = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day + ' ';
      promotion.start_date += this.start_time.hour + ':' + this.start_time.minute + ':' + this.start_time.second;
      promotion.end_date = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day + ' ';
      promotion.end_date += this.end_time.hour + ':' + this.end_time.minute + ':' + this.end_time.second;
    }

    this._promotionService.storePromotion(promotion)
      .subscribe((response: any) => {
        console.log(response);
        const data = response.data;
        const _promotion: Promotion = data;

        if (this.promotionImage) {
          console.log(this.promotionImage);
          this._uploadFileService.uploadFile(this.promotionImage, 'promociones', '', '', '', _promotion.id)
            .then(_response => console.log(_response));
        }

        swal('¡Promoción creada!', `La promoción ${ _promotion.name } ha sido creada`, 'success')
          .then(() => {
            this.router.navigate(['/promociones']);
          });
      }, error => {
        console.error(error);
        if (error.error.price) {
          swal('Error', `${error.error.price[0]}`, 'error');
        }
      });
  }

  /**
   * Method to use in the ngFor to populate the FormControls
   */
  getProductsControls() {
    return (<FormArray>this.newPromotionForm.controls['products']).controls;
  }

  getCustomValue() {
    const selectedItems = this.newPromotionForm.value.categories
      .map((v, i) => v ? this.products[i].id : null)
      .filter( v => v !== null);
      this.productsArray.push(selectedItems);
      console.log(this.productsArray);
      return selectedItems;
  }

  feedbackImage(image: File) {
    if (!image) {
      this.promotionImage = null;
      return;
    }

    if (image.type.indexOf('image') < 0) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.promotionImage = null;
      return;
    }
    this.promotionImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  /**
   * Methods to interact with the calendar
   */
  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

}
