import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from './../../config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { JWT_TOKEN, USER_STORAGE } from '../../config/store';
import { Router } from '@angular/router';
import { WebsocketService } from './../websocket/websocket.service';
import {throwError as observableThrowError } from 'rxjs';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private headers: HttpHeaders;

  constructor(
    private http: HttpClient,
    private router: Router,
    private _ws: WebsocketService,
    private _user: UsersService
  ) { }

  onLogin(user: any) {

    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/login`;

    return this.http.post(url, user, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  logout() {
    const payload = {
      nombre: 'sin-nombre',
      sala: 'admin'
    };

    this._user.user = null;
    this._user.token = null;
    this._ws.emit('configurar-usuario', payload, () => {});
    localStorage.removeItem(JWT_TOKEN);
    localStorage.removeItem(USER_STORAGE);
    this.router.navigate(['/login']);
    return observableThrowError('');
  }

  refreshToken() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/auth/refresh?token=${ jwtToken }`;

    return this.http.post(url, {}, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

    /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
