import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PromotionsService, DataTableService } from './../../services/service.index';
import { Promotion } from '../../interfaces/promotion.model';

import swal from 'sweetalert2';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit, OnDestroy {

  public promotions: Promotion[] = [];
  public promotionSub: Subscription;
  public dataTable: any;

  constructor(
    public _promoService: PromotionsService,
    private _dataTableService: DataTableService,
    private router: Router
  ) { }

  ngOnInit() {
    this.onGetPromotions();
  }

  onGetPromotions() {
    this._promoService.getAllPromotions()
      .subscribe((response: any) => {
        const data = response.data;
        this.promotions = data;

        this._dataTableService.reInitTable();
        console.log(this.promotions);
      }, error => console.log(error));
  }

  /**
   * Method to delete a promotion
   */
  deletePromotion(promotion: Promotion) {
    swal({
      title: 'Borrar Promoción',
      text: `¿Quieres borrar la promoción: ${ promotion.name }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this._promoService.deletePromotion(promotion)
          .subscribe((response: any) => {
            // console.log(response);
            const data = response.data;
            const _promotion: Promotion = data;
            swal('!Borrado!', `La promoción ${ _promotion.name } ha sido borrada`, 'success');
          }, error => swal('Error', error.error, 'error'));
      }
    });
  }

  ngOnDestroy() {
  }

}
