import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from 'src/app/config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Product } from 'src/app/interfaces/product.model';
import { JWT_TOKEN } from 'src/app/config/store';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  private headers: HttpHeaders;

  constructor(private http: HttpClient) {}

  /**
   * Function to get all products
   */
  getAllProducts() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/products?token=${ jwtToken }`;

    return this.http.get( url, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Function to handle the submit event for update
   * product
   *
   * @param product any
   */
  updateSellerProduct(product: any, product_id: number, seller_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/sellers/${ seller_id }/products/${ product_id }?token=${ jwtToken }`;

    return this.http.put(url, product, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Function to get specified product
   *
   * @param product_id number
   */
  getSpecifiedProduct(product_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/products/${ product_id }?token=${ jwtToken }`;

    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  /**
   * Method to store in localStorate product childrens
   *
   * @param childrens any
   */
  storeChildrens(childrens: any) {
    localStorage.setItem('_childrens', childrens);
  }

  /**
   * Method to load the localStorage product childrens
   *
   */
  loadChildrens() {
    if (localStorage.getItem('_childrens')) {
      return JSON.parse(localStorage.getItem('_childrens'));
    } else {
      return [];
    }
  }

  /**
   * Method to store in local storage the products
   *
   * @param categories Category
   */
  storeProducts(products: Product[]) {
    localStorage.setItem('products', JSON.stringify(products));
  }

  /**
   * Method to load the products from localStorage
   */
  loadProducts() {
    if (localStorage.getItem('products')) {
      return JSON.parse(localStorage.getItem('products'));
    } else {
      return [];
    }
  }
  /**
   * Function to store new product
   *
   * @param product any
   */
  storeSellerProduct(product: any, seller_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.headers = new HttpHeaders({'content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/sellers/${ seller_id }/products?token=${ jwtToken }`;

    return this.http.post( url, product, {headers: this.headers} )
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteSellerProduct(product: any, seller_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.headers = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/sellers/${ seller_id }/products/${ product.id }?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.headers})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
