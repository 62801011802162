import { Component, OnInit } from '@angular/core';
import { UsersService } from './../../services/service.index';
import { User } from '../../interfaces/user.interface';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import swal from 'sweetalert2';
import { USER_STORAGE } from '../../config/store';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  user: User;
  profileForm: FormGroup;
  loading =  false;

  constructor(
    public _user: UsersService
  ) {
    this.profileForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'last_name': new FormControl('', Validators.required),
      'email': new FormControl({value: '', disabled: true}, [Validators.required, Validators.email]),
      'phone': new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.user = this._user.user;
    this.fillProfile();
  }

  fillProfile() {
    this.profileForm.get('name').setValue(this.user.name);
    this.profileForm.get('last_name').setValue(this.user.last_name);
    this.profileForm.get('email').setValue(this.user.email);
    this.profileForm.get('phone').setValue(this.user.phone);
  }
  updateProfile(form: any) {
    this.loading = true;
    this._user.updateUser(form, this.user.id)
    .subscribe((response: any) => {

      console.log(response);

      const data = response.data;
      const user = JSON.stringify(data);

      swal('Actualizado', `El usuario ${response.data.name} ha sido actualizado`, 'success');
      localStorage.setItem(USER_STORAGE, user);

      this.loading = false;

    }, error => {
      console.log(error);
      swal('Error', error.error, 'error');
      this.loading = false;
    });
  }

}
