import { USER_STORAGE } from './../../config/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ProductsService, CategoriesService, SubirArchivoService } from './../../services/service.index';
import { Product } from 'src/app/interfaces/product.model';
import { Category } from './../../interfaces/category.model';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-product',
  templateUrl: './new-product.component.html',
  styleUrls: ['./new-product.component.css']
})
export class NewProductComponent implements OnInit, OnDestroy {

  public dropdownSettings = {};
  public newProductForm: FormGroup;
  private newProductSubs: Subscription;
  public products: Product[] = [];
  public selectedItems: any[] = [];
  public product_type: any;
  public sold_out: string;
  public categories: Category[] = [];
  public categoriesArray: any[] = [];
  public productImage: File;
  public prevImg: string | ArrayBuffer;

  constructor(
    public _productsService: ProductsService,
    public _categoriesService: CategoriesService,
    private router: Router,
    private _uploadService: SubirArchivoService
  ) {}

  ngOnInit() {

    const localCategories = this._categoriesService.loadCategories();
    const categoryControls = localCategories.map( () => new FormControl(false));

    /**
     * Form init
     */
    this.newProductForm = new FormGroup({
      'sku': new FormControl(''),
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
      'is_featured': new FormControl(false),
      'image': new FormControl(''),
      'price': new FormControl(1, Validators.required),
      // 'sales_price': new FormControl('0'),
      'cost': new FormControl(0),
      'content': new FormControl(),
      'sold_out': new FormControl('0'), // '0' = Stock, '1' = sold_out
      'quantity': new FormControl('1'),
      'status': new FormControl(true),
      'categories': new FormArray(categoryControls),
    });

    this.selectedItems = this._productsService.loadChildrens();
    this.cargarProductos();
    this.cargarCategorias();
    this.initDropdown();
    this.product_type = '1';
  }

  ngOnDestroy() {
    this.newProductSubs.unsubscribe();
  }
  /**
   * Method to load all categories
   */
  cargarCategorias() {
    this._categoriesService.getAllCategories()
      .subscribe((response: any) => {
        const data = response.data;
        this.categories = data;
        // console.log(this.categories);
        this._categoriesService.storeCategories(this.categories);
      }, error => {
        console.error(error);
      });
  }

  /**
   * Settings to ng-multiselect-dropdown
   */
  initDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      textField: 'name',
      idField: 'id',
      selectAllText: 'Seleccionar todos',
      unSelectAllText: 'Deseleccionar',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll: false
    };
  }
  /**
   * Function to load all the products
   */
  cargarProductos() {
    this.newProductSubs = this._productsService.getAllProducts()
      .subscribe( (response: any) => {
        const data =  response.data;
        this.products = data;
        // console.log(this.products);
      });
  }

  /**
   * Function to catch the selected item
   *
   * @param item
   */
  onItemSelect(item: any) {
    this.selectedItems.push(item);
    const duplicate = this.selectedItems.filter(itemP => itemP.id === item.id);
    if (duplicate.length > 1) {
      this.selectedItems.pop();
    }
    this.selectedItems.map(itemX => itemX.price = 0);
    console.log(this.selectedItems);
    this._productsService.storeChildrens(JSON.stringify(this.selectedItems));
  }

  /**
   * Function to filter the selected products
   * and delete form the array
   *
   * @param item any
   */
  onItemDeselect(item: any) {
    this.selectedItems = this.selectedItems.filter(product => product.id !== item.id);
    // console.log(this.selectedItems);
    this._productsService.storeChildrens(JSON.stringify(this.selectedItems));
  }

  /**
   * Function to handle the submit event
   *
   * @param product any
   */
  createProduct(product: any) {

    if (this.selectedItems.length > 0) {
      product.childrens = JSON.stringify(this.selectedItems);
    }
    if (this.categoriesArray.length > 0) {
      product.categories = this.categoriesArray;
    }
    if (product.is_featured) {
      product.is_featured = '1';
    } else {
      product.is_featured = '0';
    }
    if (product.status) {
      product.status = '1';
    } else {
      product.status = '0';
    }

    const user = localStorage.getItem(USER_STORAGE);
    const currentUser = JSON.parse(user);
    const seller_id = currentUser.id;

    this._productsService.storeSellerProduct(product, seller_id)
      .subscribe((response: any) => {
        const data = response.data;
        // const productResponse: Product = data;
        const _product: Product = data;
        console.log(response);

        this._uploadService.uploadFile(this.productImage, 'productos', '', _product.id, '', '')
        .then(_response => {
          console.log(_response);
        });

        swal('¡Producto Creado!', `El producto ${_product.name} ha sido creado`, 'success')
          .then((result) => {
            if (result.value) {
              this.router.navigate(['/productos']);
            }
          });
        // console.log(response);
      }, error => {
        console.error(error.error.quantity);
        if (error.error.price) {
          swal('Error', `${ error.error.price[0] }`, 'error');
        }
        if (error.error.quantity) {
          swal('Error', `${ error.error.quantity[0] }`, 'error');
        }
      });
  }

  /**
   * Function to handle the checkbox is featured
   *
   * @param target any
   */
  isFeatured(target: any) {

    if (target.checked) {
      this.newProductForm.get('is_featured').setValue(true);
    } else {
      this.newProductForm.get('is_featured').setValue(false);
    }
  }

  /**
   *  Function to get the of sold_out checkbox
   * @param sold_out any
   */
  getSoldOut(sold_out: any) {
    this.newProductForm.get('sold_out').setValue(sold_out);
  }

  /**
   * Get the checkbox status
   *
   * @param status any
   */
  onStatus(status: any) {
    if (status.checked) {
      this.newProductForm.get('status').setValue(true);
    } else {
      this.newProductForm.get('status').setValue(false);
    }
  }

  customResetForm() {
    this.newProductForm.reset({
      sku: '',
      name: '',
      description: '',
      is_featured: false,
      image: '',
      price: '1',
      // sales_price: '1',
      sold_out: '0',
      quantity: '0',
      status: true,
      category: ''
    });
  }

  feedbackImage(image: File) {
    if (!image) {
      this.productImage = null;
      return;
    }

    if (image.type.indexOf('image') < 0) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.productImage = null;
      return;
    }
    this.productImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  /**
   * Method to use in the ngFor to populate the FormControls
   */
  getCategoriesControls() {
    return (<FormArray>this.newProductForm.controls['categories']).controls;
  }

  /**
   * Method to store in array the selected categories
   */

  getCustomValue(event: any) {
    const id = parseInt(event.target.value, 10);
    const index = this.categoriesArray.indexOf(id);

    if (event.target.checked) {
      this.categoriesArray.push(id);
      console.log(this.categoriesArray);
    } else {
      if (index > -1) {
        this.categoriesArray.splice(index, 1);
        console.log(this.categoriesArray);
      }
    }

  }

}
