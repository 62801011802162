import { Component, OnInit } from '@angular/core';
import { SettingsService, WebsocketService } from './services/service.index';
import { USER_STORAGE } from './config/store';
import { OnesignalService } from './services/onesignal/onesignal.service';

declare function init_plugins();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    public _ajustes: SettingsService,
    private _ws: WebsocketService,
    private _oneSignal: OnesignalService
  ) {
    if (localStorage.getItem(USER_STORAGE)) {
      const user = JSON.parse(localStorage.getItem(USER_STORAGE));
      const completeName = `${ user.name } ${ user.last_name }`;
      this._ws.loginSockets(completeName);

      this._ws.listen('cliente:cancela_orden')
      .subscribe((data: any) => {
        console.log(data);
      });
    }
  }

  ngOnInit() {
    init_plugins();
    this._oneSignal.init();

    // const OneSignal = window['OneSignal'] || [];
    // console.log('Init OneSignal');
    // OneSignal.push(() => {
    //   OneSignal.init({
    //     appId: 'f8223f7d-5e67-4d48-be8f-46d548dc3ae6',
    //     autoRegister: false,
    //     allowLocalhostAsSecureOrigin: true,
    //     notifyButton: {
    //       enable: false
    //     }
    //   });
    // });

    // console.log('OneSignal Initialized');
    // OneSignal.push(function () {
    //   console.log('Register For Push');
    //   OneSignal.push(['registerForPushNotifications'])
    // });

    // OneSignal.push(function () {
    //   // Occurs when the user's subscription changes to a new value.
    //   OneSignal.on('subscriptionChange', function (isSubscribed) {
    //     console.log('The user\'s subscription state is now:', isSubscribed);
    //     OneSignal.getUserId().then(function (userId) {
    //       console.log('User ID is', userId);
    //     });
    //   });
    // });
  }
}
