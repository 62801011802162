import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UsersService, SubirArchivoService } from './../../services/service.index';
import { User } from 'src/app/interfaces/user.interface';

import swal from 'sweetalert2';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styles: []
})
export class EditUserComponent implements OnInit {

  public editUserF: FormGroup;
  private user_id: number;
  public loading =  false;
  public userImage: File;
  public user: User;
  public prevImg: string | ArrayBuffer;
  showDealerFields = false;

  constructor(
    public _usersService: UsersService,
    private activatedRoute: ActivatedRoute,
    public _uploadFileService: SubirArchivoService
  ) {

    /**
     * Get id form de url
     */
    activatedRoute.params.subscribe(params => {
      const user_id = params['id'];

      if (user_id) {
        this.user_id = user_id;
      }
    });

    /**
     * Form init
     */
    this.editUserF = new FormGroup({
      'name': new FormControl('', Validators.required),
      'last_name': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.email]),
      'phone': new FormControl('', Validators.required),
      'password': new FormControl('', [Validators.minLength(6)]),
      'password_confirmation': new FormControl(''),
      'role': new FormControl('', Validators.required),
      'number_plates': new FormControl('')
    });

    this.editUserF.controls['password_confirmation'].setValidators([
      Validators.minLength(6),
      this.noIgual.bind(this.editUserF)
    ]);

  }

  /**
   * Validator que compara los dos password para saber si son iguales o no
   */
  noIgual(control: FormControl): {[s: string]: boolean} {
    const form: any = this;

    if (control.value !== form.controls['password'].value) {
      return {
        noiguales: true
      };
    }
    return null;
  }

  ngOnInit() {
    this.userLoad(this.user_id);
  }

  updateUser(value: any) {
    this.loading = true;
    this._usersService.updateUser(value, this.user_id)
      .subscribe((response: any) => {

        swal('Actualizado', `El usuario ${response.data.name} ha sido actualizado`, 'success');
        this.loading = false;
      }, error => {
        console.log(error);
        swal('Error', error.error, 'error');
        this.loading = false;
      });
  }

  userLoad(id: number) {
    this._usersService.getEspecifiedUser(id)
      .subscribe((response: any) => {
        const data = response.data;
        const user: User = data;
        this.user = user;
        console.log(this.user);
        const role = user.role;
        this.checkRole(role);
        this.editUserF.get('name').setValue(user.name);
        this.editUserF.get('last_name').setValue(user.last_name);
        this.editUserF.get('email').setValue(user.email);
        this.editUserF.get('phone').setValue(user.phone);
        this.editUserF.get('role').setValue(user.role);

        if (user.role === '2') {
          this._usersService.getDealer(user.id)
            .subscribe((_dealer: any) => {

              const dealer = _dealer.data;
              this.editUserF.get('number_plates').setValue(dealer.number_plates);
            });
        }
      });
  }

  feedbackImage(image: File) {

    if (!image) {
      this.userImage = null;
      return;
    }

    if ( image.type.indexOf('image') < 0 ) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.userImage = null;
      return;
    }
    this.userImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }

  uploadImage() {
    this._uploadFileService.uploadFile(this.userImage, 'usuarios', this.user_id, '', '', '')
      .then((response: any) => {
        console.log(response);
        const data = response.data;
        const _user: User = data;
        swal('Imágen Actualizada', _user.name, 'success');
      })
      .catch(error => console.error(error));
  }

  checkRole(value: string) {

    if (value === '2') {
      console.log('repartidor', value);
      this.showDealerFields = true;

      this.editUserF.controls['number_plates'].setValidators([
        Validators.required
      ]);

    } else {
      this.editUserF.get('number_plates').setValue(null);
      console.log('los demas', value);
      this.showDealerFields = false;
    }
  }

}
