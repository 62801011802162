import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/url';
import { JWT_TOKEN } from 'src/app/config/store';

@Injectable({
  providedIn: 'root'
})
export class SubirArchivoService {

  constructor() { }

  uploadFile(archivo: File, tipo: string, user_id?: any, product_id?: any, category_id?: any, promotion_id?: any) {

    return new Promise( (resolve, reject) => {

      const formData = new FormData();
      const xhr = new XMLHttpRequest();
      let url: string;

      const jwtToken = localStorage.getItem(JWT_TOKEN);

      formData.append('image', archivo, archivo.name);
      formData.append('tipo', tipo);
      formData.append('_method', 'PUT');

      if (user_id) {
        url = `${ URL_SERVICIOS }/uploads/${ user_id }?token=${ jwtToken }`;
        formData.append('user_id', user_id);
      }

      if (product_id) {
        url = `${ URL_SERVICIOS }/uploads/${ product_id }?token=${ jwtToken }`;
        formData.append('product_id', product_id);
      }

      if (category_id) {
        url = `${ URL_SERVICIOS }/uploads/${ category_id }?token=${ jwtToken }`;
        formData.append('category_id', category_id);
      }

      if (promotion_id) {
        url = `${ URL_SERVICIOS }/uploads/${ promotion_id }?token=${ jwtToken }`;
        formData.append('promotion_id', promotion_id);
        // console.log(url);
      }

      xhr.onreadystatechange = () => {

        if (xhr.readyState === 4) {

          if (xhr.status === 200) {
            console.log('Imagen subida');
            // resolve(xhr.response);
            resolve(JSON.parse(xhr.response));
          } else {
            console.log('Fallo la subida');
            reject(xhr.response);
          }

        }
      };

      xhr.open('POST', url, true);
      xhr.send( formData );

    });
  }
}
