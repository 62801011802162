import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICIOS } from 'src/app/config/url';

@Pipe({
  name: 'imagen'
})
export class ImagenPipe implements PipeTransform {

  transform(img: string, tipo: string = 'usuario'): any {

    if (img === null) {
      return `${ URL_SERVICIOS }/img/no-img.jpg`;
    }

    if (img.indexOf('https') >= 0) {
      return img;
    }

    let url = `${ URL_SERVICIOS }/img`;

    switch (tipo) {
      case 'usuario':
        url += '/usuarios/' + img;
      break;
      case 'producto':
        url += '/productos/' + img;
      break;
      case 'categoria':
        url += '/categorias/' + img;
      break;
      case 'promocion':
        url += '/promociones/' + img;
      break;
      case 'no-img':
        url += img;
      break;
      default:
        console.error('Tipo de imagen no existe, usuario, producto, categoria, promocion');
        url += '/test/';
    }

    return url;
  }

}
