import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';

import { Settings } from './../../interfaces/settings.interface';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { URL_SERVICIOS } from './../../config/url';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { JWT_TOKEN } from './../../config/store';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public ajustes: Settings = {
    temaUrl: 'assets/css/colors/default.css',
    tema: 'default'
  };

  private header = new HttpHeaders;

  constructor(
    @Inject(DOCUMENT) private _document,
    private http: HttpClient
  ) {
    this.cargarAjustes();
  }

  /**
   * Función que guarda los ajustes en el localStorage
   */
  guardarAjustes() {
    localStorage.setItem('ajustes', JSON.stringify(this.ajustes));
  }

  cargarAjustes() {
    if (localStorage.getItem('ajustes')) {
      this.ajustes = JSON.parse(localStorage.getItem('ajustes'));
      this.aplicarTema(this.ajustes.tema);
    } else {
      this.aplicarTema(this.ajustes.tema);
    }
  }

  aplicarTema(tema: string) {

    const url = `assets/css/colors/${ tema }.css`;
    this._document.getElementById('tema').setAttribute('href', url);

    this.ajustes.tema = tema;
    this.ajustes.temaUrl = url;

    this.guardarAjustes();
  }

  storeLocation(polygon: any) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/places?token=${ jwtToken }`;

    return this.http.post(url, polygon, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  deleteLocation(place_id: number) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/places/${ place_id }?token=${ jwtToken }`;

    return this.http.delete(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  getAllLocations() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/places?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  getCountryStates(country_code: string) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);
    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/countries/${ country_code }/states?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  storageSelectedState(stateCoords: any) {

    if (stateCoords) {

      localStorage.setItem('selected_state', JSON.stringify(stateCoords));
      return stateCoords;
    }
  }

  loadSelectedState() {

    if (localStorage.getItem('selected_state')) {

      return JSON.parse(localStorage.getItem('selected_state'));

    } else {
      return null;
    }
  }

  getSchedulePrices() {

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/schedule`;

    return this.http.get(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Método para obtener el mínimo de compra
   */
  getMinBuy() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/min-buy?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  updateMinBuy(data: any, min_buy_id: number = 1) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/min-buy/${ min_buy_id }?token=${ jwtToken }`;

    return this.http.put(url, data, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Método para obtener el costo del servicio
   *
   */
  getServiceCost() {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/service-cost?token=${ jwtToken }`;

    return this.http.get(url, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Método para actualizar el costo del servicio
   *
   * @param data any
   * @param cost_id number
   */
  updateServiceCost(data: any, costo_id: number = 1) {

    const jwtToken = localStorage.getItem(JWT_TOKEN);

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/service-cost/${ costo_id }?token=${ jwtToken }`;

    return this.http.put(url, data, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }



  /**
   * Metodo que actualiza el porcentaje y el horario
   * en la base de datos
   *
   * @param data any
   * @param schedule_id number
   */
  updatePricePercentage(data: any, schedule_id: number) {

    this.header = new HttpHeaders({'Content-Type': 'application/json'});

    const url = `${ URL_SERVICIOS }/schedule/${ schedule_id }`;

    return this.http.put(url, data, {headers: this.header})
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Handle error
   *
   * @param error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
    console.error(
      `Backend returned code ${error.status}, ` +
      `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
  return throwError(error.error);
  }
}
