import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { CategoriesService, DataTableService, SubirArchivoService } from './../../../services/service.index';
import { Category } from './../../../interfaces/category.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import swal from 'sweetalert2';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css']
})
export class CategoriesComponent implements OnInit, OnDestroy {

  public categories: Category[] = [];
  public categoryTable: any;
  public newCategoryForm: FormGroup;
  public categoryImage: File;
  public prevImg: string | ArrayBuffer;

  @ViewChild('categoryImage') _categoryImage: ElementRef;

  constructor(
    public _categoriesService: CategoriesService,
    private _dataTableService: DataTableService,
    private _uploadService: SubirArchivoService
  ) { }

  ngOnInit() {
    this.initForm();
    this.getAllCategories();
  }

  ngOnDestroy() {
  }

  /**
   * Method to get all categories and fill
   * up the table
   *
   */
  getAllCategories() {
    this._categoriesService.getAllCategories()
      .subscribe((response: any) => {
        const data = response.data;
        this.categories = data;
        // console.log(this.categories);

        this._dataTableService.reInitTable();
      });
  }

  /**
   * Method to delete a category
   *
   */
  deleteCategory(category: Category) {
    swal({
      title: 'Borrar Categoría',
      text: `¿Quieres borrar la categoría: ${ category.name }?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, Borrar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.value) {
        this._categoriesService.deleteCategory(category)
          .subscribe( (response: any) => {
            const data: Category = response.data;
            console.log(data);
            swal('¡Borrado!', `La categoría ${ data.name } ha sido borrada`, 'success');
          }, error => {
            if (error.error.name || error.error.description) {
              swal('Error', `${ error.error.name[0] }, ${ error.error.description[0] }`, 'error');
            }
          });
      }
    });
  }

/**
 * Method to handle the form submit event
 *
 * @param category any
 */
  createNewCategory(category: any) {
    console.log(category);
    this._categoriesService.storeCategory(category)
      .subscribe((response: any) => {
        console.log(response);
        const data = response.data;
        const _category: Category = data;
        this._uploadService.uploadFile(this.categoryImage, 'categorias', '', '', _category.id, '')
          .then(_response => console.log(_response));
        swal('¡Categoría creada!', `La categoría ${ _category.name } ha sido creada`, 'success')
          .then((result) => {
            if (result.value) {
              this.customResetForm();
              this.categoryImage = null;
              this.prevImg = null;
            }
          });
      }, error => {
        if (error.error.name || error.error.description) {
          swal('Error', `${ error.error.name[0] }, ${ error.error.description[0] }`);
        }
      });
  }

  /**
   * Method to initialize the form
   *
   */
  initForm() {
    this.newCategoryForm = new FormGroup({
      'name': new FormControl('', Validators.required),
      'description': new FormControl('', Validators.required),
    });
  }

  customResetForm() {
    this._categoryImage.nativeElement.value = '';
    this.newCategoryForm.reset({
      name: '',
      description: '',
    });
  }

  feedbackImage(image: File) {
    if (!image) {
      this.categoryImage = null;
      return;
    }

    if (image.type.indexOf('image') < 0) {
      swal('Sólo imágenes', 'El archivo seleccionado no es una imágen', 'warning');
      this.categoryImage = null;
      return;
    }
    this.categoryImage = image;

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(image);

    reader.onloadend = () => this.prevImg = reader.result;
  }
}
