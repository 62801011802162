import { Injectable } from '@angular/core';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-buttons';
import 'jszip/dist/jszip';
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class DataTableService {

  public table: any;
  public reportTable: any;

  constructor() {
    this.initTable();
  }

  /**
   * Function to init the datatable.net
   */
  initTable() {
    const dataTable: any = $('#dataTable');
    this.table = dataTable.DataTable({
      'order': [[0, 'desc']],
      'language': {
        'search': 'Buscar...',
        'lengthMenu': 'Mostrar _MENU_ entradas',
        'info': 'Mostrando _START_ de _END_ de _TOTAL_ entradas',
        'paginate': {
          'first': 'Primero',
          'last': 'Último',
          'next': 'Siguiente',
          'previous': 'Anterior'
        }
      }
    });
  }

  /**
   * Function to init the table for the report
   */
  initReportTable() {
    const dataReportRable: any = $('#dataTableReport');
    this.reportTable = dataReportRable.DataTable({
      'dom': 'Bfrtip',
      'buttons': [
        'csv', 'excel', 'pdf', 'print'
      ],
      'order': [[0, 'desc']],
      'language': {
        'search': 'Buscar...',
        'lengthMenu': 'Mostrar _MENU_ entradas',
        'info': 'Mostrando _START_ de _END_ de _TOTAL_ entradas',
        'paginate': {
          'first': 'Primero',
          'last': 'Último',
          'next': 'Siguiente',
          'previous': 'Anterior'
        }
      }
    });
  }

  reInitTable() {
    if (this.table) {
      // console.log(this.table);
      this.table.destroy();
      this.table = null;
    }
    setTimeout(() => this.initTable(), 0);
  }

  reInitReportTable() {
    if (this.reportTable) {
      this.reportTable.destroy();
      this.table = null;
    }
    setTimeout(() => this.initReportTable(), 0);
  }
}
