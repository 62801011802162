import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Multiselect Dropdown
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

// Ng Bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Quill Module
import { QuillModule } from 'ngx-quill';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// ShardModule
import { SharedModule } from '../shared/shared.module';

// Pipe Module
import { PipesModule } from '../pipes/pipes.module';

// Rutas Hijas
import { PAGES_ROUTES } from './pages.routes';

// Páginas
import { PagesComponent } from './pages.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { GraficasComponent } from './graficas/graficas.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { DetailOrderComponent } from './orders/detail-order.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { NewPromotionComponent } from './promotions/new-promotion.component';
import { CatalogoComponent } from './catalogo/catalogo.component';
import { NewProductComponent } from './catalogo/new-product.component';
import { CategoriesComponent } from './catalogo/categories/categories.component';
import { UsersComponent } from './users/users.component';
import { NewUserComponent } from './users/new-user.component';
import { EditUserComponent } from './users/edit-user.component';
import { EditProductComponent } from './catalogo/edit-product.component';
import { EditCategoryComponent } from './catalogo/categories/edit-category.component';
import { EditPromotionComponent } from './promotions/edit-promotion.component';
import { SettingPlacesComponent } from './settings/setting-places.component';
import { SettingPricesComponent } from './settings/setting-prices.component';
import { SettingsCategoryComponent } from './settings/settings-category/settings-category.component';
import { SettingsGeneralComponent } from './settings/settings-general/settings-general.component';
import { CatalogoReportComponent } from './catalogo/catalogo-report/catalogo-report.component';

@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        GraficasComponent,
        AccountSettingsComponent,
        ProfileComponent,
        OrdersComponent,
        DetailOrderComponent,
        PromotionsComponent,
        NewPromotionComponent,
        CatalogoComponent,
        NewProductComponent,
        CategoriesComponent,
        UsersComponent,
        NewUserComponent,
        EditUserComponent,
        EditProductComponent,
        EditCategoryComponent,
        EditPromotionComponent,
        SettingPlacesComponent,
        SettingPricesComponent,
        SettingsCategoryComponent,
        SettingsGeneralComponent,
        CatalogoReportComponent
    ],
    exports: [
        DashboardComponent,
        GraficasComponent,
    ],
    imports: [
        SharedModule,
        PAGES_ROUTES,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgMultiSelectDropDownModule.forRoot(),
        PipesModule,
        NgbModule,
        QuillModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot()
    ]
})
export class PagesModule { }
