import { Component, OnInit, OnDestroy } from '@angular/core';

import { SidebarService, LoginService, UsersService, WebsocketService } from './../../services/service.index';
import { User } from '../../interfaces/user.interface';
import { Subscription } from 'rxjs';
import { RECEIVED_ORDERS } from '../../config/store';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: []
})
export class SidebarComponent implements OnInit, OnDestroy {

  user: User = null;
  orderSubs: Subscription;
  orders: any[] = [];

  constructor(
    public _sidebar: SidebarService,
    public _login: LoginService,
    public _user: UsersService,
    private _ws: WebsocketService,
  ) { }

  ngOnInit() {
    this.user = this._user.user;
    this.getOrders();
    // this.watchOrders();
  }

  watchOrders() {
    this.orderSubs = this._ws.listen('order.status_new').subscribe((payload: any) => {
      const data = payload.data;

      if (data) {
        this.orders.push(data);
        localStorage.setItem(RECEIVED_ORDERS, JSON.stringify(this.orders));
        console.log(this.orders);
      }

      this.getOrders();
    });
  }

  getOrders() {
    if (localStorage.getItem(RECEIVED_ORDERS)) {
      this.orders = JSON.parse(localStorage.getItem(RECEIVED_ORDERS));
      console.log(this.orders);
    }
  }

  ngOnDestroy() {
    // this.orderSubs.unsubscribe();
  }

}
